import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store, select} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, mergeMap, withLatestFrom} from 'rxjs/operators';
import {ProjectBuilderService} from '../../../services/project-builder.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  CheckTaskNameAction,
  CloneProjectTemplateAction,
  CreateProjectAction,
  CreateProjectSuccessAction,
  CreateTaskTemplateAction,
  CreateTaskTemplateSuccessAction,
  DeleteProjectTemplateAction,
  DeleteProjectTemplateSuccessAction,
  DeleteTaskTemplateAction,
  GetAllProjectTemplatesAction,
  GetAllProjectTemplatesSuccessAction,
  GetAllTaskTemplatesAction,
  GetAllTaskTemplatesSuccessAction,
  GetProjectViewerProjectAction,
  GetProjectViewerProjectSuccessAction,
  ProjectBuilderActionType,
  SaveCurrentProjectAction,
  SaveCurrentProjectSuccessAction,
  ScheduleProjectAction,
  SetCurrentProjectAction,
  SetCurrentProjectSuccessAction,
  SetSaveInProgressAction,
  SortProjectTemplatesAction,
  SortProjectTemplatesSuccessAction,
  UpdateTaskTemplateAction,
} from './project-builder.action';
import {selectProjectBuilderProjectTemplates, selectUnsavedProjectChanges} from './project-builder.selectors';
import {ConfirmDialogComponent} from './unsaved-changes-confirm/confirm-dialog.component';

@Injectable()
export class ProjectBuilderEffects {
  cloneProjectById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CloneProjectTemplateAction>(ProjectBuilderActionType.CLONE_PROJECT_TEMPLATE),
      mergeMap(action => {
        return this.projectBuilderService
          .cloneProjectById(action.payload.id)
          .pipe(mergeMap(result => [...createCallbackActions(action.payload.onSuccess, result)]));
      })
    )
  );

  sortProjectTemplates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<SortProjectTemplatesAction>(ProjectBuilderActionType.SORT_PROJECT_TEMPLATES),
      withLatestFrom(this.store$.pipe(select(selectProjectBuilderProjectTemplates))),
      mergeMap(([, templates]) => {
        const sortedTemplates = templates.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        return [new SortProjectTemplatesSuccessAction({projectTemplates: sortedTemplates})];
      })
    )
  );

  checkTaskName$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CheckTaskNameAction>(ProjectBuilderActionType.CHECK_TASK_NAME),
      mergeMap(action => {
        const {task, currentId, onSuccess, onError} = action.payload;

        return this.projectBuilderService.checkTaskName(task.label, currentId).pipe(
          mergeMap(() => {
            return [...createCallbackActions(onSuccess)];
          }),
          catchError((e: void) => {
            return [...createCallbackActions(onError, 'duplicate name')];
          })
        );
      })
    )
  );

  createProject$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateProjectAction>(ProjectBuilderActionType.CREATE_PROJECT),
      withLatestFrom(this.store$.pipe(select(selectUnsavedProjectChanges))),
      mergeMap(([action, hasUnsavedChanges]) => {
        const {onSuccess, project} = action.payload;
        if (hasUnsavedChanges) {
          return this.dialog
            .open(ConfirmDialogComponent, {
              ...new MatDialogConfig(),
              data: {
                body: `Current Project template has unsaved Changes.</br>
                     Unsaved changed will be removed.</br></br>
                     Do you want to continue?`,
              },
            })
            .afterClosed()
            .pipe(
              mergeMap(canContinue => {
                if (canContinue) {
                  return [new CreateProjectSuccessAction({project}), ...createCallbackActions(onSuccess)];
                } else {
                  return [];
                }
              })
            );
        } else {
          return [new CreateProjectSuccessAction({project}), ...createCallbackActions(onSuccess)];
        }
      })
    )
  );

  deleteProjectTemplate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteProjectTemplateAction>(ProjectBuilderActionType.DELETE_PROJECT_TEMPLATE),
      mergeMap(action => {
        const {id, onSuccess} = action.payload;

        return this.projectBuilderService
          .deleteProjectTemplate(id)
          .pipe(mergeMap(() => [new DeleteProjectTemplateSuccessAction(), ...createCallbackActions(onSuccess)]));
      })
    )
  );

  deleteTaskTemplate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteTaskTemplateAction>(ProjectBuilderActionType.DELETE_TASK_TEMPLATE),
      mergeMap(action => {
        const {id, onSuccess} = action.payload;

        return this.projectBuilderService
          .deleteTaskTemplate(id)
          .pipe(mergeMap(() => [...createCallbackActions(onSuccess)]));
      })
    )
  );

  createTaskTemplate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateTaskTemplateAction>(ProjectBuilderActionType.CREATE_TASK_TEMPLATE),
      mergeMap(action => {
        const {task, onSuccess, onFailure} = action.payload;

        return this.projectBuilderService.createTaskTemplate(task).pipe(
          mergeMap(task => [new CreateTaskTemplateSuccessAction({task}), ...createCallbackActions(onSuccess, task)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  updateTaskTemplate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateTaskTemplateAction>(ProjectBuilderActionType.UPDATE_TASK_TEMPLATE),
      mergeMap(action => {
        return this.projectBuilderService
          .updateTaskTemplate(action.payload.task)
          .pipe(mergeMap(() => [...createCallbackActions(action.payload.onSuccess)]));
      })
    )
  );

  getAllTasks$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAllTaskTemplatesAction>(ProjectBuilderActionType.GET_ALL_TASKS),
      mergeMap(action => {
        const {search, onSuccess} = action.payload || {
          onSuccess: () => null,
        };
        return this.projectBuilderService
          .getAllTasks({search})
          .pipe(
            mergeMap(result => [
              new GetAllTaskTemplatesSuccessAction(result),
              ...createCallbackActions(onSuccess, result),
            ])
          );
      })
    )
  );

  getAllProjects$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAllProjectTemplatesAction>(ProjectBuilderActionType.GET_ALL_PROJECTS),
      mergeMap(action => {
        const {search, onSuccess} = action.payload || {
          onSuccess: () => null,
        };
        return this.projectBuilderService
          .getAllProjects({search})
          .pipe(
            mergeMap(result => [
              new GetAllProjectTemplatesSuccessAction(result),
              ...createCallbackActions(onSuccess, result),
            ])
          );
      })
    )
  );

  setCurrentProject$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<SetCurrentProjectAction>(ProjectBuilderActionType.SET_CURRENT_PROJECT),
      mergeMap(action => {
        return this.projectBuilderService.getProjectById(action.payload.id).pipe(
          mergeMap(result => [
            new SetCurrentProjectSuccessAction({project: result}),
            ...createCallbackActions(action.payload.onSuccess, result),
          ]),
          catchError(error => emitErrorActions(error, action.payload.onFailure))
        );
      })
    )
  );

  saveCurrentProject$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveCurrentProjectAction>(ProjectBuilderActionType.SAVE_CURRENT_PROJECT),
      mergeMap(action => {
        const {project, onSuccess} = action.payload;

        if (project._id) {
          return this.projectBuilderService
            .updateProjectTemplate(project)
            .pipe(
              mergeMap(result => [
                new SetSaveInProgressAction({saveInProgress: false}),
                new SaveCurrentProjectSuccessAction({project: result}),
                ...createCallbackActions(onSuccess, result),
                new GetAllProjectTemplatesAction(),
              ])
            );
        }

        return this.projectBuilderService
          .saveProject(project)
          .pipe(
            mergeMap(result => [
              new SetSaveInProgressAction({saveInProgress: false}),
              new SaveCurrentProjectSuccessAction({project: result}),
              ...createCallbackActions(onSuccess, result),
              new GetAllProjectTemplatesAction(),
            ])
          );
      })
    )
  );

  scheduleProject$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<ScheduleProjectAction>(ProjectBuilderActionType.SCHEDULE_PROJECT),
      mergeMap(action => {
        const {id, projectPartial, task, taskDeleted, onSuccess, onFailure} = action.payload;
        return this.projectBuilderService.scheduleProject(id, projectPartial, task, taskDeleted).pipe(
          mergeMap(response => [new GetAllProjectTemplatesAction({}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  getProjectViewerProject$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetProjectViewerProjectAction>(ProjectBuilderActionType.GET_PROJECT_VIEWER_PROJECT),
      mergeMap(action => {
        const {id, onSuccess} = action.payload;
        return this.projectBuilderService
          .getProjectViewerProject(id)
          .pipe(
            mergeMap(project => [
              new GetProjectViewerProjectSuccessAction({project}),
              ...createCallbackActions(onSuccess, project),
            ])
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store,
    private dialog: MatDialog,
    private projectBuilderService: ProjectBuilderService
  ) {}
}
