import {Action} from '@ngrx/store';
import {Task} from 'src/app/current-sked/shared/model/task';
import {Project, ProjectViewerProject} from '../../../project-builder/shared/model/project';
import {ProjectTask, TaskTemplate} from '../../model/task';

export enum ProjectBuilderActionType {
  ADD_PROJECT_TEMPLATE = '[Project Builder] Add Project Template',

  CHECK_TASK_NAME = '[Project Builder] Check Task Name',

  CLEAR_PROJECT_BUILDER = '[Project Builder] Clear Project Builder',

  CLONE_PROJECT_TEMPLATE = '[Project Builder] Clone Project Template',

  CREATE_PROJECT = '[Project Builder] Create Project',
  CREATE_PROJECT_SUCCESS = '[Project Builder] Create Project :: Success',

  CREATE_TASK = '[Project Builder] Create task',
  CREATE_TASK_SUCCESS = '[Project Builder] Create task :: Success',

  DELETE_PROJECT_TEMPLATE = '[Project Builder] Delete project template',
  DELETE_PROJECT_TEMPLATE_SUCCESS = '[Project Builder] Delete project template :: Success',

  DELETE_TASK_TEMPLATE = '[Project Builder] Delete task template',

  GET_ALL_PROJECTS = '[Project Builder] Get Projects',
  GET_ALL_PROJECTS_SUCCESS = '[Project Builder] Get Projects :: Success',

  GET_ALL_TASKS = '[Project Builder] Get Tasks',
  GET_ALL_TASKS_SUCCESS = '[Project Builder] Get Tasks :: Success',

  SAVE_CURRENT_PROJECT = '[Project Builder] Save Current Project',
  SAVE_CURRENT_PROJECT_SUCCESS = '[Project Builder] Save Current Project :: Success',

  CREATE_TASK_TEMPLATE = '[Project Builder] Create Task Template',
  CREATE_TASK_TEMPLATE_SUCCESS = '[Project Builder] Create Task Template :: Success',

  UPDATE_TASK_TEMPLATE = '[Project Builder] Update Task Template',
  UPDATE_TASK_TEMPLATE_SUCCESS = '[Project Builder] Update Task Template :: Success',

  SET_CURRENT_PROJECT = '[Project Builder] Set Current Project',
  SET_CURRENT_PROJECT_SUCCESS = '[Project Builder] Set Current Project :: Success',
  MANUALLY_SET_CURRENT_PROJECT = '[Project Builder] Manully Set Current Project',

  SET_SAVE_IN_PROGRESS = '[Project Builder] Set Save in Progress',

  SET_TAB_INDEX = '[Project Builder] Set Tab Index',

  SET_TYPE = '[Project Builder] Set Type',

  SET_UNSAVED_CHANGES = '[Project Builder] Set Unsaved Changes',

  SORT_PROJECT_TEMPLATES = '[Project Builder] Sort Project Templates',
  SORT_PROJECT_TEMPLATES_SUCCESS = '[Project Builder] Sort Project Templates :: Success',

  SCHEDULE_PROJECT = '[Project Builder] Schedule Project',

  SET_CURRENT_PROJECT_INDEX = '[Project Builder] Set Current Project Index',

  GET_PROJECT_VIEWER_PROJECT = '[Projects] Get Project Viewer Project',
  GET_PROJECT_VIEWER_PROJECT_SUCCESS = '[Projects] Get Project Viewer Project :: Success',

  UPDATE_PROJECT_VIEWER_TASK_ID = '[Projects] Update Project Viewer Task Id',
}

export class AddProjectTemplateAction implements Action {
  public readonly type = ProjectBuilderActionType.ADD_PROJECT_TEMPLATE;

  public constructor(public payload: Project) {}
}

export class ClearProjectBuilderAction implements Action {
  public readonly type = ProjectBuilderActionType.CLEAR_PROJECT_BUILDER;
}

export class CheckTaskNameAction implements Action {
  public readonly type = ProjectBuilderActionType.CHECK_TASK_NAME;

  public constructor(
    public payload: {
      task: Task;
      currentId?: string;
      onSuccess: () => void;
      onError: (error: string) => void;
    }
  ) {}
}

export class CloneProjectTemplateAction implements Action {
  public readonly type = ProjectBuilderActionType.CLONE_PROJECT_TEMPLATE;

  public constructor(public payload: {id: string; onSuccess: (template: Project) => void}) {}
}

export class CreateProjectAction implements Action {
  public readonly type = ProjectBuilderActionType.CREATE_PROJECT;

  public constructor(
    public payload: {
      project: Project;
      onSuccess?: (project: Project) => void;
      // eslint-disable-next-line
      onError?: (e: any) => void;
    }
  ) {}
}

export class CreateProjectSuccessAction implements Action {
  public readonly type = ProjectBuilderActionType.CREATE_PROJECT_SUCCESS;

  public constructor(public payload: {project: Project}) {}
}

export class CreateTaskAction implements Action {
  public readonly type = ProjectBuilderActionType.CREATE_TASK;

  public constructor(
    public payload: {
      task: Task;
      onSuccess?: () => void;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError?: (e: any) => void;
    }
  ) {}
}

export class CreateTaskSuccessAction implements Action {
  public readonly type = ProjectBuilderActionType.CREATE_TASK_SUCCESS;

  public constructor(
    public payload: {
      task: Task;
      onSuccess?: () => void;
      onError?: (e: Error) => void;
    }
  ) {}
}

export class DeleteProjectTemplateAction implements Action {
  public readonly type = ProjectBuilderActionType.DELETE_PROJECT_TEMPLATE;

  public constructor(
    public payload: {
      id: string;
      onSuccess?: () => void;
      onError?: (e: Error) => void;
    }
  ) {}
}

export class DeleteProjectTemplateSuccessAction implements Action {
  public readonly type = ProjectBuilderActionType.DELETE_PROJECT_TEMPLATE_SUCCESS;
}

export class DeleteTaskTemplateAction implements Action {
  public readonly type = ProjectBuilderActionType.DELETE_TASK_TEMPLATE;

  public constructor(
    public payload: {
      id: string;
      onSuccess?: () => void;
      onError?: () => void;
    }
  ) {}
}

export class GetAllProjectTemplatesAction implements Action {
  public readonly type = ProjectBuilderActionType.GET_ALL_PROJECTS;

  public constructor(
    public payload?: {
      search?: string;
      onSuccess?: (projects: Project[]) => void;
    }
  ) {}
}

export class GetAllProjectTemplatesSuccessAction implements Action {
  public readonly type = ProjectBuilderActionType.GET_ALL_PROJECTS_SUCCESS;

  public constructor(public payload?: Project[]) {}
}

export class GetAllTaskTemplatesAction implements Action {
  public readonly type = ProjectBuilderActionType.GET_ALL_TASKS;

  public constructor(
    public payload?: {
      search?: string;
      onSuccess?: (tasks: Task[]) => void;
    }
  ) {}
}

export class GetAllTaskTemplatesSuccessAction implements Action {
  public readonly type = ProjectBuilderActionType.GET_ALL_TASKS_SUCCESS;

  public constructor(public payload?: Task[]) {}
}

export class CreateTaskTemplateAction implements Action {
  public readonly type = ProjectBuilderActionType.CREATE_TASK_TEMPLATE;
  public constructor(
    public payload: {
      task: TaskTemplate;
      onSuccess?: (task: TaskTemplate) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class CreateTaskTemplateSuccessAction implements Action {
  public readonly type = ProjectBuilderActionType.CREATE_TASK_TEMPLATE_SUCCESS;

  public constructor(
    public payload: {
      task: TaskTemplate;
      onSuccess?: () => void;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError?: (e: any) => void;
    }
  ) {}
}

export class UpdateTaskTemplateAction implements Action {
  public readonly type = ProjectBuilderActionType.UPDATE_TASK_TEMPLATE;
  public constructor(
    public payload: {
      task: TaskTemplate;
      onSuccess?: (task: TaskTemplate) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class UpdateTaskTemplateSuccessAction implements Action {
  public readonly type = ProjectBuilderActionType.UPDATE_TASK_TEMPLATE_SUCCESS;

  public constructor(
    public payload: {
      task: TaskTemplate;
      onSuccess?: () => void;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError?: (e: any) => void;
    }
  ) {}
}

export class SetSaveInProgressAction implements Action {
  public readonly type = ProjectBuilderActionType.SET_SAVE_IN_PROGRESS;

  public constructor(public payload: {saveInProgress: boolean}) {}
}

export class SetCurrentProjectAction implements Action {
  public readonly type = ProjectBuilderActionType.SET_CURRENT_PROJECT;

  public constructor(
    public payload: {
      id: string;
      onSuccess?: (project: Project) => void;
      onFailure?: (err: Error) => void;
    }
  ) {}
}

export class SetCurrentProjectSuccessAction implements Action {
  public readonly type = ProjectBuilderActionType.SET_CURRENT_PROJECT_SUCCESS;

  public constructor(public payload: {project: Project}) {}
}

export class ManuallySetCurrentProjectAction implements Action {
  public readonly type = ProjectBuilderActionType.MANUALLY_SET_CURRENT_PROJECT;

  public constructor(public payload: {project: Project}) {}
}

export class SetProjectBuilderTypeAction implements Action {
  public readonly type = ProjectBuilderActionType.SET_TYPE;

  public constructor(public payload: {type: 'horizontal' | 'vertical'}) {}
}

export class SetProjectBuilderTabIndexAction implements Action {
  public readonly type = ProjectBuilderActionType.SET_TAB_INDEX;

  public constructor(public payload: {tabIndex: number}) {}
}

export class SetUnsavedChangesAction implements Action {
  public readonly type = ProjectBuilderActionType.SET_UNSAVED_CHANGES;

  public constructor(public payload: boolean) {}
}

export class SortProjectTemplatesAction implements Action {
  public readonly type = ProjectBuilderActionType.SORT_PROJECT_TEMPLATES;
}

export class SortProjectTemplatesSuccessAction implements Action {
  public readonly type = ProjectBuilderActionType.SORT_PROJECT_TEMPLATES_SUCCESS;

  public constructor(public payload: {projectTemplates: Project[]}) {}
}

export class SaveCurrentProjectAction implements Action {
  public readonly type = ProjectBuilderActionType.SAVE_CURRENT_PROJECT;

  public constructor(public payload: {project: Project; onSuccess?: (project: Project) => void}) {}
}

export class SaveCurrentProjectSuccessAction implements Action {
  public readonly type = ProjectBuilderActionType.SAVE_CURRENT_PROJECT_SUCCESS;

  public constructor(public payload: {project: Project}) {}
}

export class ScheduleProjectAction implements Action {
  public readonly type = ProjectBuilderActionType.SCHEDULE_PROJECT;

  public constructor(
    public payload: {
      id: string;
      projectPartial: Partial<Project>;
      task?: ProjectTask;
      taskDeleted?: boolean;
      onSuccess?: () => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetProjectViewerProjectAction implements Action {
  public readonly type = ProjectBuilderActionType.GET_PROJECT_VIEWER_PROJECT;

  public constructor(
    public payload?: {
      id: string;
      onSuccess?: () => void;
    }
  ) {}
}

export class GetProjectViewerProjectSuccessAction implements Action {
  public readonly type = ProjectBuilderActionType.GET_PROJECT_VIEWER_PROJECT_SUCCESS;

  public constructor(public payload: {project: ProjectViewerProject}) {}
}

export class UpdateProjectViewerTaskId implements Action {
  public readonly type = ProjectBuilderActionType.UPDATE_PROJECT_VIEWER_TASK_ID;

  public constructor(public payload: {id: string}) {}
}

export type ProjectBuilderAction =
  | AddProjectTemplateAction
  | CheckTaskNameAction
  | ClearProjectBuilderAction
  | CloneProjectTemplateAction
  | CreateTaskAction
  | CreateTaskSuccessAction
  | CreateProjectAction
  | CreateProjectSuccessAction
  | DeleteTaskTemplateAction
  | GetAllProjectTemplatesAction
  | GetAllProjectTemplatesSuccessAction
  | GetAllTaskTemplatesAction
  | GetAllTaskTemplatesSuccessAction
  | SaveCurrentProjectAction
  | SaveCurrentProjectSuccessAction
  | CreateTaskTemplateAction
  | CreateTaskTemplateSuccessAction
  | UpdateTaskTemplateAction
  | UpdateTaskTemplateSuccessAction
  | SetCurrentProjectAction
  | SetCurrentProjectSuccessAction
  | SetSaveInProgressAction
  | SetProjectBuilderTabIndexAction
  | SetProjectBuilderTypeAction
  | SetUnsavedChangesAction
  | SortProjectTemplatesAction
  | SortProjectTemplatesSuccessAction
  | ScheduleProjectAction
  | GetProjectViewerProjectAction
  | GetProjectViewerProjectSuccessAction
  | UpdateProjectViewerTaskId
  | ManuallySetCurrentProjectAction;
