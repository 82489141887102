import {ObjectAttributeDto, ObjectDto} from '../dto/object.dto';
import {ObjectAttribute, ObjectModel} from '../model/object';
import {convertObjectInstanceDtoToModel} from './convert-object-instance-dto-to-model';

export function convertObjectDtoToModel(dto: ObjectDto): ObjectModel {
  if (!dto) {
    return;
  }

  return {
    id: dto._id,
    name: dto.name,
    lastUpdatedOn: dto.lastUpdatedAt,
    lastUpdatedBy: dto.lastUpdatedBy,
    attributes: dto.attributes?.map(attributeDto => convertObjectAttributeDtoToModel(attributeDto)),
    instanceCount: dto.instanceCount || null,
    instances: dto.instances?.map(instance => convertObjectInstanceDtoToModel(instance)) || [],
  };
}

function convertObjectAttributeDtoToModel(dto: ObjectAttributeDto): ObjectAttribute {
  return {
    id: dto._id || '',
    name: dto.name,
    type: dto.type,
    uom: dto.uom,
    min: dto.min,
    max: dto.max,
    options: dto.options,
    updateable: dto.updateable,
    defaultValue: dto.defaultValue,
  };
}
