import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProjectSelectorDialogComponent} from './project-selector-dialog/project-selector-dialog.component';
import {DialogLayoutModule} from '../dialog/layout/dialog-layout.module';
import {CreateProjectDialogComponent} from './create-project-dialog/create-project-dialog.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {OphIconModule} from '../design/oph-icon/oph-icon.module';
import {OphColorPickerModule} from '../design/oph-color-picker/oph-color-picker.module';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {OphInputsModule} from '../design/oph-inputs/oph-inputs.module';
import {OphButtonModule} from '../design/oph-button/oph-button.module';
import {ProjectViewerTopBarComponent} from './project-viewer-top-bar/project-viewer-top-bar.component';
import {MatMenuModule} from '@angular/material/menu';
import {PipesModule} from '../pipes/pipes.module';
import {ProjectEditMenuComponent} from './project-edit-menu/project-edit-menu.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {RenameSubtaskComponent} from './rename-task/rename-subtask.component';

@NgModule({
  declarations: [
    ProjectSelectorDialogComponent,
    CreateProjectDialogComponent,
    ProjectViewerTopBarComponent,
    ProjectEditMenuComponent,
    RenameSubtaskComponent,
  ],
  imports: [
    CommonModule,
    DialogLayoutModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    OphIconModule,
    OphColorPickerModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    OphInputsModule,
    OphButtonModule,
    MatMenuModule,
    PipesModule,
    MatProgressSpinnerModule,
  ],
  exports: [ProjectSelectorDialogComponent, ProjectViewerTopBarComponent, ProjectEditMenuComponent],
})
export class SharedProjectsModule {}
