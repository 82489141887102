import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Store, select} from '@ngrx/store';
import {Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {ChangeCurrentSkedThemeAction} from 'src/app/core/store/current-sked/current-sked.action';
import {SetTeamSkedsAction} from 'src/app/core/store/scheduler/scheduler.action';
import {selectTeam} from 'src/app/core/store/teams/teams.selector';
import {selectActiveUser} from '../../../../core/store/active-user/active-user.selector';
import {selectRouterUrl} from '../../../../core/store/router/router.selector';
import {selectTeamSkeds} from '../../../../core/store/scheduler/scheduler.selectors';
import {CurrentSkedThemes} from 'src/app/current-sked/shared/current-sked-themes';

@Component({
  selector: 'oph-top-bar',
  templateUrl: './oph-top-bar.component.html',
  styleUrls: ['./oph-top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphTopBarComponent implements OnInit {
  @Input() currentSkedTheme: CurrentSkedThemes;

  @Output() toggleMenu = new EventEmitter();

  public readonly url$ = this.store$.pipe(select(selectRouterUrl));
  public readonly activeUser$ = this.store$.select(selectActiveUser);
  userTeamName$: Observable<string>;

  public scheduleSelected$: Observable<boolean> = this.store$.pipe(select(selectTeamSkeds));
  currentSkedScreen$: Observable<boolean>;

  currentSkedThemes = CurrentSkedThemes;

  constructor(
    private router: Router,
    private store$: Store
  ) {}

  ngOnInit() {
    this.currentSkedScreen$ = this.observeUrl();
    this.userTeamName$ = this.observeUser();
  }

  observeUrl(): Observable<boolean> {
    return this.url$.pipe(
      filter(url => !!url),
      map(url => url.startsWith('/current-sked'))
    );
  }

  observeUser(): Observable<string> {
    return this.activeUser$.pipe(
      filter(user => !!user?.teams?.length),
      switchMap(user => this.store$.pipe(select(selectTeam(user.teams[0])))),
      map(team => team?.name || 'Team name not found')
    );
  }

  onThemeMenu(theme: CurrentSkedThemes) {
    if (theme === this.currentSkedThemes.Dubai) {
      this.router.navigate(['current-sked']);
    }
    this.store$.dispatch(new ChangeCurrentSkedThemeAction({theme}));
  }

  onSelectChange($event: boolean) {
    this.store$.dispatch(new SetTeamSkedsAction($event));
  }
}
