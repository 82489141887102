import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Store, select} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';

import {GetProgramsAction} from 'src/app/core/store/programs/programs.action';
import {selectAllPrograms} from 'src/app/core/store/programs/programs.selector';
import {SaveCurrentProjectAction} from 'src/app/core/store/project-builder/project-builder.action';
import {Program} from 'src/app/programs/shared/model/program';
import {ProjectWizardDialogComponent} from 'src/app/project-builder/project-wizard-dialog/project-wizard-dialog.component';
import {Project} from 'src/app/project-builder/shared/model/project';
import {EMPTY_PROJECT} from 'src/app/project-builder/shared/project-builder-contants';
import {TimezoneService} from 'src/app/services/timezone.service';
import {ProjectsService} from '../../services/projects.service';
import {PROJECT_WIZARD_DIALOG_CONFIG} from './../../../project-builder/shared/project-builder-contants';

@Component({
  selector: 'project-viewer-top-bar',
  templateUrl: './project-viewer-top-bar.component.html',
  styleUrls: ['./project-viewer-top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectViewerTopBarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() projects: Project;
  @Input() currentProject: Project;

  @Output() currentProjectChange = new EventEmitter<string>();

  @ViewChildren('menuItems') menuItems: QueryList<ElementRef>;

  subscriptions = new Subscription();

  programs$: Observable<Program[]>;

  isProjectActive: boolean;

  constructor(
    private store$: Store,
    private dialog: MatDialog,
    private router: Router,
    private projectsService: ProjectsService,
    private timezoneService: TimezoneService
  ) {}

  ngOnInit() {
    this.store$.dispatch(new GetProgramsAction({}));
    this.programs$ = this.store$.pipe(select(selectAllPrograms));

    this.subscriptions.add(this.subscribeToGetClick());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentProject && this.currentProject) {
      this.isProjectActive = this.timezoneService.areDatesWithinRange(
        this.currentProject.startDateTime,
        this.currentProject.endDateTime
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  subscribeToGetClick(): Subscription {
    return this.projectsService.getClickEvent().subscribe(() => this.onNewProject());
  }

  onProjectSelect(id: string) {
    localStorage.setItem('ophProjectId', id);
    this.setCurrentProjectAction(id);
  }

  setCurrentProjectAction(id: string) {
    this.currentProjectChange.emit(id);
  }

  onNewProject() {
    const project = {...EMPTY_PROJECT};
    const dialog = this.dialog.open(ProjectWizardDialogComponent, {
      ...PROJECT_WIZARD_DIALOG_CONFIG,
      data: {project, programs$: this.programs$},
    });
    dialog.afterClosed().subscribe(projectData => {
      if (projectData) {
        this.saveProject(projectData);
      }
    });
  }

  saveProject(project: Project) {
    this.store$.dispatch(
      new SaveCurrentProjectAction({
        project,
        onSuccess: p => {
          this.setCurrentProjectAction(p._id);
          this.router.navigate(['project-builder']);
        },
      })
    );
  }

  onMenuOpened(currentProject: Project, projects: Project[]) {
    if (!currentProject) {
      return;
    }
    setTimeout(() => {
      const selectedIndex = projects.findIndex(p => p.label === currentProject.label);
      if (selectedIndex !== -1) {
        const selectedItem = this.menuItems.toArray()[selectedIndex];
        selectedItem?.nativeElement?.scrollIntoView({block: 'center'});
      }
    }, 0);
  }
}
