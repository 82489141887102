<div class="modal-body dialog">
  @if (!!(bodyContent$ | async)) {
    <div>
      <div [innerHTML]="bodyContent$ | async"></div>
    </div>
  }
  @if ((bodyContent$ | async) === null) {
    <div>
      Your Project has unsaved changes. <br />
      If you leave, the changes will be lost.
    </div>
  }
</div>
<div class="actions-wrap">
  <button mat-raised-button class="cancel" (click)="cancel()">Cancel</button>
  <button mat-raised-button class="confirm" (click)="confirm()">Continue</button>
</div>
