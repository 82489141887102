import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MENU_ITEMS, MenuItem} from 'src/app/app.constants';
import {CurrentSkedThemes} from 'src/app/current-sked/shared/current-sked-themes';

@Component({
  selector: 'desktop-sidebar',
  templateUrl: './desktop-sidebar.component.html',
  styleUrls: ['./desktop-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopSidebarComponent implements OnChanges {
  @Input() routerUrl: string;
  @Input() currentSkedTheme: CurrentSkedThemes;

  menuItems: MenuItem[] = MENU_ITEMS;

  selectedIndex$ = new BehaviorSubject<number>(null);

  ngOnChanges(changes: SimpleChanges) {
    if (changes.routerUrl && this.routerUrl) {
      const index = this.menuItems.findIndex(item => this.routerUrl.startsWith(item.url));
      this.selectedIndex$.next(index);
    }
  }
}
