@if ({currentSkedTheme: currentSkedTheme$ | async, onCurrentSked: onCurrentSked$ | async}; as state) {
  <header>
    <oph-top-bar [currentSkedTheme]="state.currentSkedTheme"></oph-top-bar>
  </header>
  <main>
    @if ((isSchedulerWeekView() | async) === false && state.currentSkedTheme !== currentSkedThemes.Dubai) {
      <desktop-sidebar
        [routerUrl]="routerUrl$ | async"
        [currentSkedTheme]="state.currentSkedTheme"
        class="desktop-sidebar"
      ></desktop-sidebar>
    }
    <div class="desktop-content" [class.dubai-theme]="state.currentSkedTheme === currentSkedThemes.Dubai">
      <ng-content></ng-content>
    </div>
  </main>
}
