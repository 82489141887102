@if ({currentSkedTheme: currentSkedTheme$ | async, showTopbar: showTopbar$ | async}; as state) {
  <mat-drawer-container class="drawer-container" [hasBackdrop]="true" mode="over">
    <mat-drawer #drawer>
      <!-- inside drawer -->
      <mobile-navbar
        [user]="activeUser$ | async"
        [routerUrl]="routerUrl$ | async"
        (close)="drawer.toggle()"
        (mobileNavItemClick)="drawer.toggle()"
        (logout)="onLogout()"
      ></mobile-navbar>
    </mat-drawer>
    <mat-drawer-content>
      @if (state.currentSkedTheme === currentSkedThemes.Dubai && state.showTopbar) {
        <current-sked-dubai-theme-top-bar
          [user]="activeUser$ | async"
          mobile="true"
          (themeChange)="onThemeChange($event)"
        ></current-sked-dubai-theme-top-bar>
      } @else {
        <oph-top-bar [currentSkedTheme]="state.currentSkedTheme" (toggleMenu)="drawer.toggle()"></oph-top-bar>
      }
      <div class="ng-content-container">
        <ng-content></ng-content>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
}
