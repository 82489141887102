import {ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Store, select} from '@ngrx/store';
import {BehaviorSubject, Observable, Subscription, combineLatest} from 'rxjs';
import {distinctUntilChanged, map, throttleTime} from 'rxjs/operators';
import {AuthenticationService} from 'src/app/auth/auth.service';
import {MobileService} from 'src/app/core/page/mobile.service';
import {ChangeCurrentSkedThemeAction} from 'src/app/core/store/current-sked/current-sked.action';
import {selectCurrentSkedTheme} from 'src/app/core/store/current-sked/current-sked.selector';
import {selectRouterUrl} from 'src/app/core/store/router/router.selector';
import {selectActiveUser, selectActiveUserPrivileges} from '../../../core/store/active-user/active-user.selector';
import {CurrentSkedThemes} from 'src/app/current-sked/shared/current-sked-themes';

@Component({
  selector: 'mobile-layout',
  templateUrl: './mobile-layout.component.html',
  styleUrls: ['./mobile-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileLayoutComponent implements OnInit, OnDestroy {
  public routerUrl$: Observable<string>;
  public privileges$: Observable<string[]>;
  public phone$: Observable<boolean>;
  currentSkedTheme$: Observable<CurrentSkedThemes>;
  showTopbar$: Observable<boolean>;
  public activeUser$ = this.store$.select(selectActiveUser);

  screenWidth$ = new BehaviorSubject<number>(null);

  private subscriptions = new Subscription();

  currentSkedThemes = CurrentSkedThemes;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth$.next(window.innerWidth);
  }

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private mobileService: MobileService,
    private store$: Store
  ) {}

  public ngOnInit() {
    this.screenWidth$.next(window.innerWidth);
    this.routerUrl$ = this.store$.select(selectRouterUrl);
    this.privileges$ = this.store$.select(selectActiveUserPrivileges);
    this.phone$ = this.mobileService.observePhone().pipe(throttleTime(500));
    this.currentSkedTheme$ = this.store$.pipe(select(selectCurrentSkedTheme));
    this.showTopbar$ = this.observeScreenWidth();

    this.subscriptions.add(this.subscribeToPhone());
  }

  observeScreenWidth(): Observable<boolean> {
    return combineLatest([this.screenWidth$, this.routerUrl$]).pipe(
      map(([width, url]) => {
        return url?.startsWith('/current-sked') && width < 960;
      })
    );
  }

  private subscribeToPhone(): Subscription {
    return this.phone$.pipe(distinctUntilChanged()).subscribe(phone => {
      if (phone) {
        // this.router.navigate(['current-sked']);
      }
    });
  }

  public onLogout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onThemeChange(theme: CurrentSkedThemes) {
    this.store$.dispatch(new ChangeCurrentSkedThemeAction({theme}));
  }
}
