import {Sked} from 'src/app/current-sked/shared/model/sked';
import {CurrentSkedTask} from '../../model/task';
import {CurrentSkedThemes} from 'src/app/current-sked/shared/current-sked-themes';
import {CurrentSkedTabOptions} from 'src/app/current-sked/task-list/shared/current-sked-tab-options';

export interface CurrentSkedState {
  currentSked: Sked;
  skeds: Sked[];
  task: CurrentSkedTask;
  theme: CurrentSkedThemes;
  selectedSkedType: 'current' | 'all';
  activeTab: CurrentSkedTabOptions;
  updatedTask: CurrentSkedTask;
  expandedSubtaskIndex: number;
}

export const initialCurrentSkedState: CurrentSkedState = {
  currentSked: null,
  skeds: [],
  task: null,
  theme: CurrentSkedThemes.Default,
  selectedSkedType: 'current',
  activeTab: CurrentSkedTabOptions.Sked,
  updatedTask: {} as CurrentSkedTask,
  expandedSubtaskIndex: null,
};
