@if (
  {
    currentSkedScreen: currentSkedScreen$ | async,
    activeUser: activeUser$ | async,
    url: url$ | async,
    scheduleSelected: scheduleSelected$ | async,
    userTeamName: userTeamName$ | async
  };
  as state
) {
  <div
    class="container"
    [class.default-theme]="currentSkedTheme === currentSkedThemes.Default || !state.currentSkedScreen"
    [class.dubai-theme]="currentSkedTheme === currentSkedThemes.Dubai && state.currentSkedScreen"
  >
    @if (currentSkedTheme === currentSkedThemes.Default || !state.currentSkedScreen) {
      <div>
        <!-- This was the button to open the menu for current sked theme selection. It is currently not being used -->
        <!-- <button mat-button [matMenuTriggerFor]="menu" class="ophanim-button"> -->
        <button mat-button disabled class="ophanim-button">
          <img src="assets/img/fullsked-logo.svg" />
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="onThemeMenu(currentSkedThemes.Default)">Default</button>
          <button mat-menu-item (click)="onThemeMenu(currentSkedThemes.Dubai)">Dubai</button>
        </mat-menu>
      </div>
      @if (state.url?.startsWith('/schedule') && !state.url?.startsWith('/scheduler')) {
        <scheduler-toolbar [activeUser]="state.activeUser"></scheduler-toolbar>
      }
      @if (state.url === '/project') {
        <project-viewer-top-bar></project-viewer-top-bar>
      }
      @if (state.url === '/scheduler') {
        <button class="schedule-menu-button" [matMenuTriggerFor]="scheduleMenu">
          <span>{{ state.scheduleSelected === false ? 'Personal' : state.userTeamName }}</span>
          <oph-icon name="caret-down-brown" size="10"></oph-icon>
        </button>
        <mat-menu #scheduleMenu="matMenu">
          <button mat-menu-item (click)="onSelectChange(false)" [class.selected]="state.scheduleSelected === false">
            Personal
          </button>
          @if (state.activeUser?.teams.length > 0) {
            <button mat-menu-item (click)="onSelectChange(true)" [class.selected]="state.scheduleSelected === true">
              {{ state.userTeamName }}
            </button>
          }
        </mat-menu>
      }
      <div class="right-container">
        @if (state.url === '/current-sked') {
          <current-sked-selector></current-sked-selector>
        }
        <user-menu [mobile]="false" class="user-menu-margin"></user-menu>
        <button class="menu-toggle-button" (click)="toggleMenu.emit()">
          <img src="assets/img/icons/menu.svg" />
        </button>
      </div>
    }
    @if (currentSkedTheme === currentSkedThemes.Dubai && state.currentSkedScreen) {
      <current-sked-dubai-theme-top-bar
        class="dubai-top-bar"
        [user]="state.activeUser"
        mobile="false"
        (themeChange)="onThemeMenu($event)"
      ></current-sked-dubai-theme-top-bar>
    }
  </div>
}
