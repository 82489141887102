@if (
  {
    selectedSkedIndex: selectedSkedIndex$ | async,
    showPreviousButton: showPreviousButton$ | async,
    showNextButton: showNextButton$ | async,
    allSkeds: allSkeds$ | async,
    selectedSked: selectedSked$ | async,
    screenWidth: screenWidth$ | async,
    url: url$ | async
  };
  as state
) {
  <div class="main-container" [class.mobile-screen]="state.screenWidth < 960">
    <button [matMenuTriggerFor]="menu" class="ophanim-dubai-button">
      <img src="assets/img/fullsked-logo.svg" />
    </button>
    <mat-menu #menu="matMenu" class="oph-select-menu">
      <button mat-menu-item (click)="onThemeMenu(currentSkedThemes.Default)">Default</button>
      <button mat-menu-item (click)="onThemeMenu(currentSkedThemes.Dubai)">Dubai</button>
    </mat-menu>
    @if (state.screenWidth >= 1080) {
      <div class="display-container user-container" [matMenuTriggerFor]="userMenu">
        <div class="yellow-fill-in-left"></div>
        <img
          src="assets/img/icons/union-shape-short-yellow.svg"
          class="union-shape-short-yellow-left-user yellow-img"
        />
        <div class="light-yellow-fill-in"></div>
        <img src="assets/img/icons/union-shape-long-yellow.svg" class="union-shape-long-yellow-left-user yellow-img" />
        <div class="avatar">{{ (user.username || '')[0] }}</div>
        <span class="user-name">{{ user.username || '' }}</span>
        <div class="yellow-fill-in-right"></div>
      </div>
    }
    <mat-menu #userMenu="matMenu" xPosition="before" class="oph-select-menu">
      <button class="logout-button" (click)="onLogout()">
        <oph-icon name="logout" size="30"></oph-icon>
        <span>Logout</span>
      </button>
    </mat-menu>
    @if (state.screenWidth < 1080) {
      <div class="small-user-container" [matMenuTriggerFor]="userMenu">
        <div class="avatar">{{ (user?.username || '')[0] }}</div>
      </div>
    }
  </div>
}
