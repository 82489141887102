import {Action} from '@ngrx/store';
import {Sked} from 'src/app/current-sked/shared/model/sked';
import {TaskStatusActions} from 'src/app/current-sked/shared/task-status-actions';
import {ObjectInstance} from 'src/app/objects/shared/model/object-instance';
import {CurrentSkedTask} from '../../model/task';
import {CurrentSkedThemes} from 'src/app/current-sked/shared/current-sked-themes';
import {CurrentSkedTabOptions} from 'src/app/current-sked/task-list/shared/current-sked-tab-options';

export enum CurrentSkedActionType {
  GET = '[Current Sked] Get',
  GET_SUCCESS = '[Current Sked] Get :: Success',

  GET_ALL_SKEDS = '[Current Sked] Get All Skeds',
  GET_ALL_SKEDS_SUCCESS = '[Current Sked] Get All Skeds :: Success',

  GET_TASK = '[Current Sked] Get Task',
  GET_TASK_SUCCESS = '[Current Sked] Get Task :: Success',

  CHANGE_TASK_STATUS = '[Current Sked] Change Task Status',
  CHANGE_TASK_STATUS_SUCCESS = '[Current Sked] Change Task Status :: Success',

  CHANGE_SUBTASK_STATUS = '[Current Sked] Change Subtask Status',
  CHANGE_SUBTASK_STATUS_SUCCESS = '[Current Sked] Change Subtask Status :: Success',

  UPDATE_SUBTASK_ATTRIBUTE = '[Current Sked] Update Subtask Attribute',
  UPDATE_SUBTASK_ATTRIBUTE_SUCCESS = '[Current Sked] Update Subtask Attribute :: Success',

  CHANGE_THEME = '[Current Sked] Change Theme',

  UPDATE_ALL_SKEDS = '[Current Sked] Update All Skeds',
  UPDATE_ACTIVE_TAB = '[Current Sked] Update Active Tab',
  UPDATE_SELECTED_SKED_TYPE = '[Current Sked] Update Selected Sked Type',
  UPDATE_EXPANDED_SUBTASK_INDEX = '[Current Sked] Update Expanded Subtask Index',

  UPDATE_CURRENT_SKED = '[Current Sked] Update Current Sked',
  UPDATE_CURRENT_SKED_SUCCESS = '[Current Sked] Update Current Sked :: Success',
}

export class GetCurrentSkedAction implements Action {
  public readonly type = CurrentSkedActionType.GET;

  public constructor(
    public payload: {
      onSuccess?: (currentSked: Sked) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetCurrentSkedSuccessAction implements Action {
  public readonly type = CurrentSkedActionType.GET_SUCCESS;

  public constructor(public payload: {currentSked: Sked}) {}
}

//------------------------------------------------------------

export class GetCurrentSkedSkeds implements Action {
  public readonly type = CurrentSkedActionType.GET_ALL_SKEDS;

  public constructor(
    public payload: {
      onSuccess?: (skeds: Sked[]) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetCurrentSkedSkedsSuccessAction implements Action {
  public readonly type = CurrentSkedActionType.GET_ALL_SKEDS_SUCCESS;

  public constructor(public payload: {skeds: Sked[]}) {}
}

//------------------------------------------------------------

export class GetCurrentSkedTaskAction implements Action {
  public readonly type = CurrentSkedActionType.GET_TASK;

  public constructor(
    public payload: {
      taskId: string;
      onSuccess?: (task: CurrentSkedTask) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetCurrentSkedTaskSuccessAction implements Action {
  public readonly type = CurrentSkedActionType.GET_TASK_SUCCESS;

  public constructor(public payload: {task: CurrentSkedTask}) {}
}

//------------------------------------------------------------

export class CurrentSkedChangeTaskStatusAction implements Action {
  public readonly type = CurrentSkedActionType.CHANGE_TASK_STATUS;

  public constructor(
    public payload: {
      id: string;
      actionType: TaskStatusActions;
      skedId: string;
      onSuccess?: (task: CurrentSkedTask) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class CurrentSkedChangeTaskStatusSuccessAction implements Action {
  public readonly type = CurrentSkedActionType.CHANGE_TASK_STATUS_SUCCESS;

  public constructor(public payload: {task: CurrentSkedTask}) {}
}

//------------------------------------------------------------

export class CurrentSkedChangeSubtaskStatusAction implements Action {
  public readonly type = CurrentSkedActionType.CHANGE_SUBTASK_STATUS;

  public constructor(
    public payload: {
      taskId: string;
      subtaskId: string;
      done: boolean;
      onSuccess?: (task: CurrentSkedTask) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class CurrentSkedChangeSubtaskStatusSuccessAction implements Action {
  public readonly type = CurrentSkedActionType.CHANGE_SUBTASK_STATUS_SUCCESS;

  public constructor(public payload: {objectInstance: ObjectInstance}) {}
}

//------------------------------------------------------------
export class CurrentSkedUpdateSubtaskAttributeAction implements Action {
  public readonly type = CurrentSkedActionType.UPDATE_SUBTASK_ATTRIBUTE;

  public constructor(
    public payload: {
      value: string;
      objectId: string;
      updateableAttributeId: string;
      onSuccess?: () => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class CurrentSkedUpdateSubtaskAttributeSuccessAction implements Action {
  public readonly type = CurrentSkedActionType.UPDATE_SUBTASK_ATTRIBUTE_SUCCESS;

  public constructor(public payload: {objectInstance: ObjectInstance}) {}
}

//------------------------------------------------------------

export class ChangeCurrentSkedThemeAction implements Action {
  public readonly type = CurrentSkedActionType.CHANGE_THEME;

  public constructor(public payload: {theme: CurrentSkedThemes}) {
    sessionStorage.setItem('ophTheme', payload.theme);
  }
}

export class UpdateAllSkedsAction implements Action {
  public readonly type = CurrentSkedActionType.UPDATE_ALL_SKEDS;

  public constructor(public payload: {allSkeds: Sked[]}) {}
}

export class UpdateCurrentSkedActiveTabAction implements Action {
  public readonly type = CurrentSkedActionType.UPDATE_ACTIVE_TAB;

  public constructor(public payload: {activeTab: CurrentSkedTabOptions}) {}
}

export class UpdateSelectedSkedTypeAction implements Action {
  public readonly type = CurrentSkedActionType.UPDATE_SELECTED_SKED_TYPE;

  public constructor(public payload: {selectedSkedType: 'current' | 'all'}) {}
}

export class UpdateCurrentSked implements Action {
  public readonly type = CurrentSkedActionType.UPDATE_CURRENT_SKED;

  public constructor(public payload: {sked: Sked; onSuccess?: (sked: Sked) => void}) {}
}

export class UpdateCurrentSkedSuccess implements Action {
  public readonly type = CurrentSkedActionType.UPDATE_CURRENT_SKED_SUCCESS;

  public constructor(public payload: {sked: Sked}) {}
}

export class UpdateCurrentSkedExpandedSubtaskIndex implements Action {
  public readonly type = CurrentSkedActionType.UPDATE_EXPANDED_SUBTASK_INDEX;

  public constructor(public payload: {expandedSubtaskIndex: number}) {}
}

export type CurrentSkedAction =
  | GetCurrentSkedAction
  | GetCurrentSkedSuccessAction
  | GetCurrentSkedSkeds
  | GetCurrentSkedSkedsSuccessAction
  | GetCurrentSkedTaskAction
  | GetCurrentSkedTaskSuccessAction
  | CurrentSkedChangeTaskStatusAction
  | CurrentSkedChangeTaskStatusSuccessAction
  | CurrentSkedChangeSubtaskStatusAction
  | CurrentSkedChangeSubtaskStatusSuccessAction
  | ChangeCurrentSkedThemeAction
  | UpdateAllSkedsAction
  | UpdateCurrentSkedActiveTabAction
  | UpdateSelectedSkedTypeAction
  | UpdateCurrentSked
  | UpdateCurrentSkedExpandedSubtaskIndex;
