import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ObjectInstance} from 'src/app/objects/shared/model/object-instance';
import {ObjectListParams} from 'src/app/objects/shared/model/object-list-params';
import {environment} from '../../../environments/environment';
import {ObjectInstanceDto} from '../../objects/shared/dto/object-instance.dto';
import {ObjectAttributeDto, ObjectDto} from '../../objects/shared/dto/object.dto';
import {ObjectModel} from 'src/app/objects/shared/model/object';

@Injectable({
  providedIn: 'root',
})
export class ObjectsApiService {
  public url = `${environment.apiUrl}/objects`;

  constructor(private http: HttpClient) {}

  public get(params: ObjectListParams): Observable<ObjectDto[]> {
    return this.http.get<ObjectDto[]>(this.url, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public getSingle(id: string): Observable<ObjectDto[]> {
    return this.http.get<ObjectDto[]>(`${this.url}/${id}`);
  }

  public getCombined(): Observable<ObjectModel[]> {
    return this.http.get<ObjectModel[]>(`${this.url}/combined`);
  }

  public getInstances(modelId: string): Observable<ObjectInstanceDto[]> {
    return this.http.get<ObjectInstanceDto[]>(`${environment.apiUrl}/objectInstances/childrenOf/${modelId}`);
  }

  public getAllInstances(): Observable<ObjectInstanceDto[]> {
    return this.http.get<ObjectInstanceDto[]>(`${environment.apiUrl}/objectInstances`);
  }

  public getInstancesFromInstanceId(instanceId: string): Observable<ObjectInstanceDto[]> {
    return this.http.get<ObjectInstanceDto[]>(`${environment.apiUrl}/objectInstances/${instanceId}`);
  }

  public createInstance(object: ObjectDto): Observable<ObjectInstanceDto> {
    return this.http.post<ObjectInstanceDto>(`${environment.apiUrl}/objectInstances`, object);
  }

  public create(object: Partial<ObjectDto>): Observable<ObjectDto> {
    return this.http.post<ObjectDto>(`${this.url}`, object);
  }

  public duplicate(id: string): Observable<ObjectDto> {
    return this.http.post<ObjectDto>(`${this.url}/${id}`, {});
  }

  public updateName(id: string, name: string): Observable<ObjectDto> {
    return this.http.put<ObjectDto>(`${this.url}/${id}`, {name});
  }

  public delete(id: string): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }

  public updateAttribute(objectId: string, attribute: ObjectAttributeDto, newAttribute: boolean): Observable<Object> {
    return this.http.patch(`${this.url}/updateAttribute/${objectId}?new=${String(newAttribute)}`, attribute);
  }

  public deleteAttribute(instanceId: string, attribute: ObjectAttributeDto): Observable<Object> {
    return this.http.patch(`${this.url}/deleteAttribute/${instanceId}`, attribute);
  }

  public instanceValueChange(objectInstanceId: string, instanceAttributeId: string, value: string): Observable<Object> {
    return this.http.patch(`${environment.apiUrl}/objectInstances/${objectInstanceId}/attribute`, {
      instanceAttributeId,
      value,
    });
  }

  public updateInstance(instance: ObjectInstance): Observable<Object> {
    return this.http.patch(`${environment.apiUrl}/objectInstances/${instance.id}`, {name: instance.name});
  }

  public deleteInstance(id: string): Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/objectInstances/${id}`);
  }
}
