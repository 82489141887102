import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {ActionReducerMap, MetaReducer, StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../../../environments/environment';
import {AppState, initialAppState} from './app-state';
import {CommonEffects} from './common/common.effects';
import {commonMetaReducer} from './common/common.meta-reducer';
import {RolesEffects} from './roles/roles.effects';
import {rolesReducer} from './roles/roles.reducer';
import {customRouterReducer} from './router/router.reducer';
import {CustomRouterStateSerializer} from './router/router.state';
import {UsersEffects} from './users/users.effects';
import {usersReducer} from './users/users.reducer';
import {activeUserReducer} from './active-user/active-user.reducer';
import {privilegesReducer} from './privileges/privileges.reducer';
import {PrivilegesEffects} from './privileges/privileges.effects';
import {projectBuilderReducer} from './project-builder/project-builder.reducer';
import {ProjectBuilderEffects} from './project-builder/project-builder.effects';
import {ConfirmDialogComponent} from './project-builder/unsaved-changes-confirm/confirm-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {schedulerReducer} from './scheduler/scheduler.reducer';
import {SchedulerEffects} from './scheduler/scheduler.effects';
import {CommonModule} from '@angular/common';
import {skillsReducer} from './skills/skills.reducer';
import {SkillsEffects} from './skills/skills.effects';
import {ProgramsEffects} from './programs/programs.effects';
import {programsReducer} from './programs/programs.reducer';
import {LocationsEffects} from './locations/locations.effects';
import {locationsReducer} from './locations/locations.reducer';
import {ActionsEffects} from './actions/actions.effects';
import {actionsReducer} from './actions/actions.reducer';
import {teamsReducer} from './teams/teams.reducer';
import {TeamsEffects} from './teams/teams.effects';
import {ObjectsEffects} from './objects/objects.effects';
import {objectsReducer} from './objects/objects.reducer';
import {ReportingEffects} from './reporting/reporting.effects';
import {reportingReducer} from './reporting/reporting.reducer';
import {currentSkedReducer} from './current-sked/current-sked.reducer';
import {CurrentSkedEffects} from './current-sked/current-sked.effects';
import {lensesReducer} from './lenses/lenses.reducer';
import {LensesEffects} from './lenses/lenses.effects';
import {scheduleReducer} from './schedule/schedule.reducer';
import {ScheduleEffects} from './schedule/schedule.effects';
import {goalsReducer} from './goals/goals.reducer';
import {GoalsEffects} from './goals/goals.effects';
import {ActiveUserEffects} from './active-user/active-user.effect';

const reducers: ActionReducerMap<AppState> = {
  activeUser: activeUserReducer,
  privileges: privilegesReducer,
  roles: rolesReducer,
  router: customRouterReducer,
  projectBuilder: projectBuilderReducer,
  scheduler: schedulerReducer,
  skills: skillsReducer,
  users: usersReducer,
  programs: programsReducer,
  locations: locationsReducer,
  actions: actionsReducer,
  teams: teamsReducer,
  objects: objectsReducer,
  reporting: reportingReducer,
  currentSked: currentSkedReducer,
  lenses: lensesReducer,
  schedule: scheduleReducer,
  goals: goalsReducer,
};

const metaReducers: MetaReducer<AppState>[] = [commonMetaReducer];

const effects = [
  CommonEffects,
  PrivilegesEffects,
  RolesEffects,
  ProjectBuilderEffects,
  SchedulerEffects,
  SkillsEffects,
  UsersEffects,
  ProgramsEffects,
  LocationsEffects,
  ActionsEffects,
  TeamsEffects,
  ObjectsEffects,
  ReportingEffects,
  CurrentSkedEffects,
  LensesEffects,
  ScheduleEffects,
  GoalsEffects,
  ActiveUserEffects,
];

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, {
      initialState: initialAppState,
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: false, // TODO change to true once this is fixed: https://github.com/ngrx/platform/issues/2690,
        strictStateImmutability: false, // TODO change to true once this is fixed: https://github.com/ngrx/platform/issues/2690,
      },
    }),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot(),
    environment.storeDevtools
      ? StoreDevtoolsModule.instrument({maxAge: 50, name: `Ophanim NgRx Store`, connectInZone: true})
      : [],
    MatButtonModule,
    CommonModule,
  ],
  declarations: [ConfirmDialogComponent],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStateSerializer,
    },
  ],
})
export class AppStoreModule {}
