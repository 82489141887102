import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store, select} from '@ngrx/store';
import {Observable} from 'rxjs';
import {mergeMap, withLatestFrom} from 'rxjs/operators';
import {ScheduleAPIService} from '../../api/schedule-api.service';
import {createCallbackActions} from '../store.utils';
import {
  CreateAutoSked,
  CreateAutoSkedSuccess,
  DeleteAutoSked,
  DeleteAutoSkedSuccess,
  GetAutoSked,
  GetAutoSkedSuccess,
  GetAutoSkeds,
  GetAutoSkedsSuccess,
  GetSkedsAction,
  GetSkedsSuccessAction,
  ScheduleActionType,
  UpdateAutoSked,
  UpdateAutoSkedSuccess,
} from './schedule.action';
import {selectActiveUser} from '../active-user/active-user.selector';

@Injectable()
export class ScheduleEffects {
  getAutoSkeds$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAutoSkeds>(ScheduleActionType.GET_AUTOSKEDS),
      withLatestFrom(this.store$.pipe(select(selectActiveUser))),
      mergeMap(([action, user]) => {
        const {onSuccess} = action.payload;
        return this.scheduleAPI.getAutoSkeds(user._id).pipe(
          mergeMap(response => {
            return [new GetAutoSkedsSuccess({autoSkeds: response}), ...createCallbackActions(onSuccess, response)];
          })
        );
      })
    )
  );

  getAutoSked$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAutoSked>(ScheduleActionType.GET_AUTOSKED),
      mergeMap(action => {
        const {autoSked, onSuccess} = action.payload;
        return this.scheduleAPI.getAutoSked(autoSked).pipe(
          mergeMap(response => {
            return [new GetAutoSkedSuccess({autoSked: response}), ...createCallbackActions(onSuccess, response)];
          })
        );
      })
    )
  );

  createAutoSked$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateAutoSked>(ScheduleActionType.CREATE_AUTOSKED),
      mergeMap(action => {
        const {autoSked, onSuccess} = action.payload;
        return this.scheduleAPI.createAutoSked(autoSked).pipe(
          mergeMap(response => {
            return [new CreateAutoSkedSuccess({autoSked: response}), ...createCallbackActions(onSuccess, response)];
          })
        );
      })
    )
  );

  updateAutoSked$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateAutoSked>(ScheduleActionType.UPDATE_AUTOSKED),
      mergeMap(action => {
        const {autoSked, onSuccess} = action.payload;
        return this.scheduleAPI.updateAutoSked(autoSked).pipe(
          mergeMap(response => {
            return [new UpdateAutoSkedSuccess({autoSked: response}), ...createCallbackActions(onSuccess, response)];
          })
        );
      })
    )
  );

  deleteAutoSked$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteAutoSked>(ScheduleActionType.DELETE_AUTOSKED),
      mergeMap(action => {
        const {autoSked, onSuccess} = action.payload;
        return this.scheduleAPI.deleteAutoSked(autoSked).pipe(
          mergeMap(response => {
            return [new DeleteAutoSkedSuccess({autoSked: response}), ...createCallbackActions(onSuccess, response)];
          })
        );
      })
    )
  );

  getSkeds$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSkedsAction>(ScheduleActionType.GET_SKEDS),
      mergeMap(action => {
        const {params, onSuccess} = action.payload;
        return this.scheduleAPI.getSkeds(params).pipe(
          mergeMap(skeds => {
            return [new GetSkedsSuccessAction({skeds}), ...createCallbackActions(onSuccess, skeds)];
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private scheduleAPI: ScheduleAPIService,
    private store$: Store
  ) {}
}
