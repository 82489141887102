import {Component, ChangeDetectionStrategy, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {
  public readonly bodyContent$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    @Inject(MAT_DIALOG_DATA) data: {body: string},
    private dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {
    if (data?.body) {
      this.bodyContent$.next(data.body);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  confirm() {
    this.dialogRef.close(true);
  }
}
