import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {convertObjectInstanceDtoToModel} from 'src/app/objects/shared/converters/convert-object-instance-dto-to-model';
import {ObjectInstanceDto} from 'src/app/objects/shared/dto/object-instance.dto';
import {CurrentSkedApiService} from '../../api/current-sked-api.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  CurrentSkedActionType,
  CurrentSkedChangeSubtaskStatusAction,
  CurrentSkedChangeTaskStatusAction,
  CurrentSkedChangeTaskStatusSuccessAction,
  CurrentSkedUpdateSubtaskAttributeAction,
  CurrentSkedUpdateSubtaskAttributeSuccessAction,
  GetCurrentSkedAction,
  GetCurrentSkedSkeds,
  GetCurrentSkedSkedsSuccessAction,
  GetCurrentSkedSuccessAction,
  GetCurrentSkedTaskAction,
  GetCurrentSkedTaskSuccessAction,
  UpdateCurrentSked,
  UpdateCurrentSkedSuccess,
} from './current-sked.action';

@Injectable()
export class CurrentSkedEffects {
  public get$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetCurrentSkedAction>(CurrentSkedActionType.GET),
      mergeMap(action => {
        const {onSuccess, onFailure} = action.payload;

        return this.currentSkedApiService.get().pipe(
          mergeMap(currentSked => [
            new GetCurrentSkedSuccessAction({currentSked}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getAllSkeds$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetCurrentSkedSkeds>(CurrentSkedActionType.GET_ALL_SKEDS),
      mergeMap(action => {
        const {onSuccess, onFailure} = action.payload;

        return this.currentSkedApiService.getAllSkeds().pipe(
          mergeMap(skeds => [new GetCurrentSkedSkedsSuccessAction({skeds}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getTask$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetCurrentSkedTaskAction>(CurrentSkedActionType.GET_TASK),
      mergeMap(action => {
        const {taskId, onSuccess, onFailure} = action.payload;

        return this.currentSkedApiService.getCurrentSkedTask(taskId).pipe(
          mergeMap(task => [new GetCurrentSkedTaskSuccessAction({task}), ...createCallbackActions(onSuccess, task)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public changeTaskStatus$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CurrentSkedChangeTaskStatusAction>(CurrentSkedActionType.CHANGE_TASK_STATUS),
      mergeMap(action => {
        const {id, actionType, skedId, onSuccess, onFailure} = action.payload;

        return this.currentSkedApiService.changeTaskStatus(id, actionType, skedId).pipe(
          mergeMap(task => [
            new CurrentSkedChangeTaskStatusSuccessAction({task}),
            ...createCallbackActions(onSuccess, task),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public changeSubtaskStatus$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CurrentSkedChangeSubtaskStatusAction>(CurrentSkedActionType.CHANGE_SUBTASK_STATUS),
      mergeMap(action => {
        const {taskId, subtaskId, done, onSuccess, onFailure} = action.payload;

        return this.currentSkedApiService.changeSubTaskStatus(taskId, subtaskId, done).pipe(
          mergeMap(task => [
            new CurrentSkedChangeTaskStatusSuccessAction({task}),
            ...createCallbackActions(onSuccess, task),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public updateSubtaskAttribute$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CurrentSkedUpdateSubtaskAttributeAction>(CurrentSkedActionType.UPDATE_SUBTASK_ATTRIBUTE),
      mergeMap(action => {
        const {value, objectId, updateableAttributeId, onSuccess, onFailure} = action.payload;

        return this.currentSkedApiService.updateSubtaskAttributeValue(value, objectId, updateableAttributeId).pipe(
          map((dto: ObjectInstanceDto) => convertObjectInstanceDtoToModel(dto)),
          mergeMap(objectInstance => [
            new CurrentSkedUpdateSubtaskAttributeSuccessAction({objectInstance}),
            ...createCallbackActions(onSuccess, objectInstance),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public updateCurrentSked$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateCurrentSked>(CurrentSkedActionType.UPDATE_CURRENT_SKED),
      mergeMap(action => {
        const {sked, onSuccess} = action.payload;
        return this.currentSkedApiService.updateCurrentSked(sked).pipe(
          mergeMap(response => {
            return [new UpdateCurrentSkedSuccess({sked: response}), ...createCallbackActions(onSuccess, response)];
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private currentSkedApiService: CurrentSkedApiService
  ) {}
}
