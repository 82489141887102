import {ObjectAttributeDto, ObjectDto} from '../dto/object.dto';
import {ObjectAttribute, ObjectModel} from '../model/object';
import {convertObjectInstanceModelToDto} from './convert-object-instance-model-to-dto';

export function convertObjectModelToDto(dto: ObjectModel): ObjectDto {
  return {
    _id: dto.id,
    name: dto.name,
    lastUpdatedAt: dto.lastUpdatedOn,
    lastUpdatedBy: dto.lastUpdatedBy,
    attributes: dto.attributes?.map(attributeDto => convertObjectAttributeModelToDto(attributeDto)),
    instances: dto.instances?.map(instance => convertObjectInstanceModelToDto(instance)) || [],
  };
}

export function convertObjectAttributeModelToDto(dto: ObjectAttribute): ObjectAttributeDto {
  return {
    _id: dto.id || '',
    name: dto.name,
    type: dto.type,
    uom: dto.uom,
    min: dto.min,
    max: dto.max,
    options: dto.options,
    updateable: dto.updateable,
    defaultValue: dto.defaultValue,
  };
}
