import {Injectable} from '@angular/core';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {
  timezone = 'America/New_York';

  convertUtcToTimezone(timeStamp: string, timestampFormat?: string): string {
    return moment(timeStamp)
      .tz(this.timezone)
      .format(timestampFormat || 'hh:mma');
  }

  convertDateToUtcString(date: Date): string {
    return moment(date).utc().format();
  }

  isDateToday(date: string): boolean {
    return moment.tz(date, this.timezone).isSame(this.now, 'day');
  }

  areDatesWithinRange(startDate: string, endDate: string): boolean {
    const startMoment = moment.tz(startDate, this.timezone);
    const endMoment = moment.tz(endDate, this.timezone);
    return this.now.isBetween(startMoment, endMoment, 'day', '[]');
  }

  getTimeZone(): string {
    return this.timezone;
  }

  get now() {
    return moment().tz(this.timezone).startOf('day');
  }
}
