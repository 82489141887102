import {ObjectInstance} from 'src/app/objects/shared/model/object-instance';
import {ObjectModel} from '../../../objects/shared/model/object';

export interface ObjectsState {
  objects: ObjectModel[];
  object: ObjectModel;
  combinedObjects: ObjectModel[];
  objectInstances: ObjectInstance[];
  allObjectInstances: ObjectInstance[];
}

export const initialObjectsState: ObjectsState = {
  objects: [],
  object: null,
  combinedObjects: null,
  objectInstances: null,
  allObjectInstances: null,
};
