import {HttpErrorResponse} from '@angular/common/http';
import {Action} from '@ngrx/store';
import {ObjectInstance} from 'src/app/objects/shared/model/object-instance';
import {ObjectAttribute, ObjectModel} from '../../../objects/shared/model/object';
import {ObjectListParams} from '../../../objects/shared/model/object-list-params';

export enum ObjectsActionType {
  GET = '[Objects] Get',
  GET_SUCCESS = '[Objects] Get :: Success',

  GET_SINGLE = '[Objects] Get Single',
  GET_SINGLE_SUCCESS = '[Objects] Get Single :: Success',
  CLEAR_SINGLE = '[Objects] Clear Single',

  GET_COMBINED = '[Objects] Get Combined',
  GET_COMBINED_SUCCESS = '[Objects] Get Combined :: Success',

  CREATE = '[Objects] Create',
  CREATE_SUCCESS = '[Objects] Create :: Success',

  DUPLICATE = '[Objects] Duplicate',
  DUPLICATE_SUCCESS = '[Objects] Duplicate :: Success',

  UPDATE_NAME = '[Objects] Update Name',
  UPDATE_NAME_SUCCESS = '[Objects] Update Name :: Success',

  DELETE = '[Objects] Delete',
  DELETE_SUCCESS = '[Objects] Delete :: Success',

  UPDATE_ATTRIBUTE = '[Objects] Update Attribute',
  UPDATE_ATTRIBUTE_SUCCESS = '[Objects] Update Attribute :: Success',

  DELETE_ATTRIBUTE = '[Objects] Delete Attribute',
  DELETE_ATTRIBUTE_SUCCESS = '[Objects] Delete Attribute :: Success',

  GET_ALL_INSTANCES = '[Objects] Get All Instances',
  GET_ALL_INSTANCES_SUCCESS = '[Objects] Get All Instances :: Success',

  GET_INSTANCES = '[Objects] Get Instances',
  GET_INSTANCES_SUCCESS = '[Objects] Get Instances :: Success',

  GET_INSTANCES_FROM_INSTANCE_ID = '[Objects] Get Instances From Instance Id',
  GET_INSTANCES_FROM_INSTANCE_ID_SUCCESS = '[Objects] Get Instances From Instance Id :: Success',

  CREATE_INSTANCE = '[Objects] Create Instance',
  CREATE_INSTANCE_SUCCESS = '[Objects] Create Instance :: Success',

  VALUE_CHANGE = '[Objects] Value Change',
  VALUE_CHANGE_SUCCESS = '[Objects] Value Change :: Success',

  UPDATE_INSTANCE = '[Objects] Update Instance',
  UPDATE_INSTANCE_SUCCESS = '[Objects] Update Instance :: Success',

  DELETE_INSTANCE = '[Objects] Delete Instance',
  DELETE_INSTANCE_SUCCESS = '[Objects] Delete Instance :: Success',

  CLEAR = '[Objects] Clear',
}

export class GetObjectsAction implements Action {
  public readonly type = ObjectsActionType.GET;

  public constructor(
    public payload: {
      params?: ObjectListParams;
      onSuccess?: (objects: ObjectModel[]) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetObjectsSuccessAction implements Action {
  public readonly type = ObjectsActionType.GET_SUCCESS;

  public constructor(public payload: {objects: ObjectModel[]}) {}
}

export class GetSingleObjectAction implements Action {
  public readonly type = ObjectsActionType.GET_SINGLE;

  public constructor(
    public payload: {
      id: string;
      onSuccess?: (object: ObjectModel) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetSingleObjectSuccessAction implements Action {
  public readonly type = ObjectsActionType.GET_SINGLE_SUCCESS;

  public constructor(public payload: {object: ObjectModel}) {}
}

//This call includes objects with all object instances and all attributes. Other calls do not include all this info
export class GetCombinedObjectsAction implements Action {
  public readonly type = ObjectsActionType.GET_COMBINED;

  public constructor(
    public payload: {
      onSuccess?: (objects: ObjectModel[]) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetCombinedObjectsSuccessAction implements Action {
  public readonly type = ObjectsActionType.GET_COMBINED_SUCCESS;

  public constructor(public payload: {objects: ObjectModel[]}) {}
}
export class ClearSingleObjectAction implements Action {
  public readonly type = ObjectsActionType.CLEAR_SINGLE;
}

export class CreateObjectAction implements Action {
  public readonly type = ObjectsActionType.CREATE;

  public constructor(
    public payload: {
      object: ObjectModel;
      onSuccess?: (object: ObjectModel) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class CreateObjectSuccessAction implements Action {
  public readonly type = ObjectsActionType.CREATE_SUCCESS;

  public constructor(public payload: {object: ObjectModel}) {}
}

export class DuplicateObjectAction implements Action {
  public readonly type = ObjectsActionType.DUPLICATE;

  public constructor(
    public payload: {
      id: string;
      onSuccess?: (object: ObjectModel) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class DuplicateObjectSuccessAction implements Action {
  public readonly type = ObjectsActionType.DUPLICATE_SUCCESS;

  public constructor(public payload: {object: ObjectModel}) {}
}

export class UpdateObjectNameAction implements Action {
  public readonly type = ObjectsActionType.UPDATE_NAME;

  public constructor(
    public payload: {
      id: string;
      name: string;
      onSuccess?: (object: ObjectModel) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class UpdateObjectNameSuccessAction implements Action {
  public readonly type = ObjectsActionType.UPDATE_NAME_SUCCESS;
  public constructor(public payload: {object: ObjectModel}) {}
}

export class DeleteObjectAction implements Action {
  public readonly type = ObjectsActionType.DELETE;

  public constructor(
    public payload: {
      id: string;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class DeleteObjectSuccessAction implements Action {
  public readonly type = ObjectsActionType.DELETE_SUCCESS;
}

export class UpdateObjectAttributeAction implements Action {
  public readonly type = ObjectsActionType.UPDATE_ATTRIBUTE;

  public constructor(
    public payload: {
      objectId: string;
      attribute: ObjectAttribute;
      newAttribute: boolean;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class UpdateObjectAttributeSuccessAction implements Action {
  public readonly type = ObjectsActionType.UPDATE_ATTRIBUTE_SUCCESS;
}

export class DeleteObjectAttributeAction implements Action {
  public readonly type = ObjectsActionType.DELETE_ATTRIBUTE;

  public constructor(
    public payload: {
      objectId: string;
      attribute: ObjectAttribute;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class DeleteObjectAttributeSuccessAction implements Action {
  public readonly type = ObjectsActionType.DELETE_ATTRIBUTE_SUCCESS;
}

export class GetObjectInstancesAction implements Action {
  public readonly type = ObjectsActionType.GET_INSTANCES;

  public constructor(
    public payload: {
      modelId: string;
      onSuccess?: (objectInstances: ObjectInstance[]) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetObjectInstancesSuccessAction implements Action {
  public readonly type = ObjectsActionType.GET_INSTANCES_SUCCESS;

  public constructor(public payload: {objectInstances: ObjectInstance[]}) {}
}

export class GetAllObjectInstancesAction implements Action {
  public readonly type = ObjectsActionType.GET_ALL_INSTANCES;

  public constructor(
    public payload: {
      onSuccess?: (objectInstances: ObjectInstance[]) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetAllObjectInstancesSuccessAction implements Action {
  public readonly type = ObjectsActionType.GET_ALL_INSTANCES_SUCCESS;

  public constructor(public payload: {objectInstances: ObjectInstance[]}) {}
}

export class GetObjectInstancesFromInstanceIdAction implements Action {
  public readonly type = ObjectsActionType.GET_INSTANCES_FROM_INSTANCE_ID;

  public constructor(
    public payload: {
      instanceId: string;
      onSuccess?: (instances: ObjectInstance[]) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetObjectInstancesFromInstanceIdSuccessAction implements Action {
  public readonly type = ObjectsActionType.GET_INSTANCES_FROM_INSTANCE_ID_SUCCESS;

  public constructor(public payload: {objectInstances: ObjectInstance[]}) {}
}

export class CreateObjectInstanceAction implements Action {
  public readonly type = ObjectsActionType.CREATE_INSTANCE;

  public constructor(
    public payload: {
      object: ObjectModel;
      onSuccess?: (objectInstance: ObjectInstance) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class CreateObjectInstanceSuccessAction implements Action {
  public readonly type = ObjectsActionType.CREATE_INSTANCE_SUCCESS;

  public constructor(public payload: {objectInstance: ObjectInstance}) {}
}

export class ObjectInstanceValueChangeAction implements Action {
  public readonly type = ObjectsActionType.VALUE_CHANGE;

  public constructor(
    public payload: {
      objectInstanceId: string;
      instanceAttributeId: string;
      value: string;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class ObjectInstanceValueChangeSuccessAction implements Action {
  public readonly type = ObjectsActionType.VALUE_CHANGE_SUCCESS;
}

export class UpdateObjectInstanceAction implements Action {
  public readonly type = ObjectsActionType.UPDATE_INSTANCE;

  public constructor(
    public payload: {
      objectInstance: ObjectInstance;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class UpdateObjectInstanceSuccessAction implements Action {
  public readonly type = ObjectsActionType.UPDATE_INSTANCE_SUCCESS;
}

export class DeleteObjectInstanceAction implements Action {
  public readonly type = ObjectsActionType.DELETE_INSTANCE;

  public constructor(
    public payload: {
      id: string;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class DeleteObjectInstanceSuccessAction implements Action {
  public readonly type = ObjectsActionType.DELETE_INSTANCE_SUCCESS;
}

export class ClearObjectAction implements Action {
  public readonly type = ObjectsActionType.DELETE_INSTANCE_SUCCESS;
}

export class ClearObjectsAction implements Action {
  public readonly type = ObjectsActionType.CLEAR;
}

export type ObjectsAction =
  | GetObjectsAction
  | GetObjectsSuccessAction
  | GetSingleObjectAction
  | GetSingleObjectSuccessAction
  | GetCombinedObjectsAction
  | GetCombinedObjectsSuccessAction
  | GetAllObjectInstancesAction
  | GetAllObjectInstancesSuccessAction
  | GetObjectInstancesAction
  | GetObjectInstancesSuccessAction
  | CreateObjectInstanceAction
  | CreateObjectInstanceSuccessAction
  | ClearSingleObjectAction
  | CreateObjectAction
  | CreateObjectSuccessAction
  | DuplicateObjectAction
  | DuplicateObjectSuccessAction
  | UpdateObjectNameAction
  | UpdateObjectNameSuccessAction
  | DeleteObjectAction
  | DeleteObjectSuccessAction
  | UpdateObjectAttributeAction
  | UpdateObjectAttributeSuccessAction
  | UpdateObjectInstanceAction
  | UpdateObjectInstanceSuccessAction
  | DeleteObjectAttributeAction
  | DeleteObjectAttributeSuccessAction
  | DeleteObjectInstanceAction
  | DeleteObjectInstanceSuccessAction
  | ClearObjectsAction;
