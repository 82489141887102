import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {BehaviorSubject, Observable, Subscription, combineLatest} from 'rxjs';
import {take} from 'rxjs/operators';
import {AuthenticationService} from 'src/app/auth/auth.service';
import {selectCurrentSkedSkeds} from 'src/app/core/store/current-sked/current-sked.selector';
import {selectRouterUrl} from 'src/app/core/store/router/router.selector';
import {Sked} from 'src/app/current-sked/shared/model/sked';
import {CurrentSkedThemes} from '../../current-sked-themes';
import {User} from 'src/app/core/model/user';

@Component({
  selector: 'current-sked-dubai-theme-top-bar',
  templateUrl: './current-sked-dubai-theme-top-bar.component.html',
  styleUrls: ['./current-sked-dubai-theme-top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentSkedDubaiThemeTopBarComponent implements OnInit, OnDestroy {
  // Variables were removed from this component to avoid errors. This component is no longer supported
  subscriptions = new Subscription();

  @Input() user: User;

  @Output() themeChange = new EventEmitter<CurrentSkedThemes>();

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth$.next(window.innerWidth);
  }

  selectedSkedIndex$: Observable<number>;
  allSkeds$: Observable<Sked[]>;
  url$: Observable<string>;

  selectedSked$ = new BehaviorSubject<Sked>(null);
  showPreviousButton$ = new BehaviorSubject<boolean>(false);
  showNextButton$ = new BehaviorSubject<boolean>(false);
  screenWidth$ = new BehaviorSubject<number>(null);

  currentSkedThemes = CurrentSkedThemes;

  constructor(
    private authService: AuthenticationService,
    private store$: Store
  ) {}

  ngOnInit() {
    this.screenWidth$.next(window.innerWidth);
    this.allSkeds$ = this.store$.pipe(select(selectCurrentSkedSkeds));
    this.url$ = this.store$.pipe(select(selectRouterUrl));
    this.subscriptions.add(this.subscribeToSelectedSkedIndex());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  subscribeToSelectedSkedIndex(): Subscription {
    return combineLatest([this.selectedSkedIndex$, this.allSkeds$, this.url$]).subscribe(([index, skeds, url]) => {
      if ((index !== 0 && !index) || !skeds?.length) {
        return;
      }
      this.selectedSked$.next(skeds[index]);
      if (url.startsWith('/current-sked/')) {
        this.showPreviousButton$.next(false);
        this.showNextButton$.next(false);
        return;
      }
      this.showPreviousButton$.next(index > 0 && skeds.length > 0);
      this.showNextButton$.next(skeds.length > 1 && skeds.length !== index + 1);
    });
  }

  onThemeMenu(theme: CurrentSkedThemes) {
    this.themeChange.emit(theme);
  }

  onSkedChange(value: string) {
    this.selectedSkedIndex$.pipe(take(1)).subscribe(index => {
      return;
    });
  }

  onLogout() {
    this.authService.logout();
  }
}
