import {ObjectInstanceAttributeDto, ObjectInstanceDto} from '../dto/object-instance.dto';
import {ObjectInstance, ObjectInstanceAttribute} from '../model/object-instance';

export function convertObjectInstanceDtoToModel(dto: ObjectInstanceDto): ObjectInstance {
  return {
    id: dto._id,
    modelId: dto.modelId,
    name: dto.name,
    location: dto.location,
    owner: dto.owner,
    createdOn: dto.createdAt,
    createdBy: dto.createdBy,
    instanceAttributes: dto.instanceAttributes.map(a => convertInstanceAttributesDtoToModel(a)) || [],
  };
}

function convertInstanceAttributesDtoToModel(dto: ObjectInstanceAttributeDto): ObjectInstanceAttribute {
  return {
    id: dto._id,
    defaultValue: dto.defaultValue,
    max: dto.max,
    min: dto.min,
    name: dto.name,
    modelAttributeId: dto.modelAttributeId,
    value: dto.value,
    options: dto.options,
    type: dto.type,
    updateable: dto.updateable,
    lastUpdatedAt: dto.lastUpdatedAt,
    lastUpdatedBy: dto.lastUpdatedBy,
  };
}
