import {ObjectsAction, ObjectsActionType} from './objects.action';
import {initialObjectsState, ObjectsState} from './objects.state';

export function objectsReducer(state: ObjectsState = initialObjectsState, action: ObjectsAction): ObjectsState {
  switch (action.type) {
    case ObjectsActionType.GET_SUCCESS:
      return {...state, objects: action.payload.objects};
    case ObjectsActionType.GET_SINGLE_SUCCESS:
      return {...state, object: action.payload.object};
    case ObjectsActionType.GET_COMBINED_SUCCESS:
      return {...state, combinedObjects: action.payload.objects};
    case ObjectsActionType.UPDATE_NAME_SUCCESS:
      return {...state, object: action.payload.object};
    case ObjectsActionType.GET_INSTANCES_SUCCESS:
      return {...state, objectInstances: action.payload.objectInstances};
    case ObjectsActionType.GET_ALL_INSTANCES_SUCCESS:
      return {...state, allObjectInstances: action.payload.objectInstances};
    case ObjectsActionType.CLEAR_SINGLE:
      return {...state, object: null};
    case ObjectsActionType.CLEAR:
      return {...state, object: null, objectInstances: null};
    default:
      return state;
  }
}
