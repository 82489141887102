@if (currentProject) {
  <div class="left-container">
    <button class="new-project-button" (click)="onNewProject()">
      <oph-icon name="plus-white"></oph-icon>
    </button>
    <button class="project-selector-button" [matMenuTriggerFor]="menu" (click)="onMenuOpened(currentProject, projects)">
      @if (currentProject) {
        <div class="project-color" [style.background-color]="currentProject?.color || '#E5E3DC'"></div>
      }
      <span class="button-text">{{ currentProject ? currentProject?.label || 'UNTITLED' : '' }}</span>
      <oph-icon name="chevron-down-orange" size="24"></oph-icon>
    </button>
    <mat-menu #menu="matMenu" class="oph-select-menu">
      @for (project of projects; track project; let i = $index) {
        <button
          class="oph-select-menu-item"
          [class.selected]="currentProject && project.label === currentProject.label"
          (click)="onProjectSelect(project._id)"
        >
          <div #menuItems class="menu-item-container">
            <div class="project-color" [style.background-color]="project.color"></div>
            <span class="project-display">{{ project.label || 'UNTITLED' }}</span>
          </div>
        </button>
      }
      @if (!projects?.length) {
        <div class="no-projects">No Projects were found</div>
      }
    </mat-menu>
  </div>
  <div class="right-container">
    @if (currentProject) {
      @if (currentProject?.status !== 'inactive' && isProjectActive) {
        <span class="project-date"
          >{{ currentProject.startDateTime | convertUtcToTimezone: 'MMM D YYYY' }} -
          {{ currentProject.endDateTime | convertUtcToTimezone: 'MMM D YYYY' }}</span
        >
      }
      <project-edit-menu [project]="currentProject" [projects]="projects"></project-edit-menu>
    }
  </div>
}
