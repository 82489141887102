import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {ProjectTask, TaskTemplate} from '../core/model/task';
import {Task} from '../current-sked/shared/model/task';
import {Project, ProjectViewerProject} from '../project-builder/shared/model/project';

@Injectable({
  providedIn: 'root',
})
export class ProjectBuilderService {
  private readonly url: string = `${environment.apiUrl}`;

  constructor(private http: HttpClient) {}

  cloneProjectById(id: string) {
    return this.http.post(`${this.url}/projects/templates/${id}`, null);
  }

  checkTaskName(taskName: string, currentId: string) {
    return this.http.get<Task[]>(`${this.url}/tasks/templates?label=${taskName}`).pipe(
      map(result => {
        const hasDuplicate = result.some(i => i.label === taskName && i._id !== currentId);

        if (hasDuplicate) {
          throw Error();
        } else {
          return result;
        }
      })
    );
  }

  getAllProjects({search}: {search: string}): Observable<Project[]> {
    let queries = '';

    if (search) {
      queries += `&label=${search}`;
    }

    return this.http.get<Project[]>(`${this.url}/projects/templates${queries ? '?' + queries : ''}`);
  }

  getProjectById(id: string): Observable<Project> {
    return this.http.get<Project>(`${this.url}/projects/templates/${id}`);
  }

  saveProject(project: Project): Observable<Project> {
    return this.http.post<Project>(`${this.url}/projects/templates`, project);
  }

  updateProjectTemplate(project: Project): Observable<Project> {
    return this.http.put<Project>(`${this.url}/projects/templates/${project._id}`, project);
  }

  scheduleProject(
    id: string,
    schedule: Partial<Project>,
    task: ProjectTask | null,
    taskDeleted: boolean
  ): Observable<Project> {
    const payload = {
      ...schedule,
      task,
    };
    return this.http.patch<Project>(`${this.url}/projects/templates/${id}?taskDeleted=${String(taskDeleted)}`, payload);
  }

  getAllTasks({search}: {search: string}): Observable<Task[]> {
    let queries = '';

    if (search) {
      queries += `&label=${search}`;
    }

    return this.http.get<Task[]>(`${this.url}/tasks/templates${queries ? '?' + queries : ''}`);
  }

  getTaskById(id: string): Observable<Task> {
    return this.http.get<Task>(`${this.url}/tasks/templates/${id}`);
  }

  createTaskTemplate(task: TaskTemplate): Observable<ProjectTask> {
    return this.http.post<ProjectTask>(`${this.url}/tasks/templates`, task);
  }

  updateTaskTemplate(task: TaskTemplate): Observable<ProjectTask> {
    return this.http.put<ProjectTask>(`${this.url}/tasks/templates/${task._id}`, task);
  }

  deleteProjectTemplate(id: string) {
    return this.http.delete(`${this.url}/projects/templates/${id}`);
  }

  deleteTaskTemplate(id: string) {
    return this.http.delete(`${this.url}/tasks/templates/${id}`);
  }

  getProjectViewerProject(id: string): Observable<ProjectViewerProject> {
    return this.http.get<ProjectViewerProject>(`${this.url}/projects/viewer/${id}`);
  }
}
