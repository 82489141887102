import {ObjectInstanceDto} from '../dto/object-instance.dto';
import {ObjectInstance} from '../model/object-instance';

export function convertObjectInstanceModelToDto(model: ObjectInstance): ObjectInstanceDto {
  return {
    _id: model.id || '',
    modelId: model.modelId,
    name: model.name,
    location: model.location,
    owner: model.owner,
    createdAt: model.createdOn,
    createdBy: model.createdBy,
    instanceAttributes: model.instanceAttributes,
  };
}
