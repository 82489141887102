import {PROJECT_WIZARD_DIALOG_CONFIG} from './../../../project-builder/shared/project-builder-contants';
import {TimezoneService} from 'src/app/services/timezone.service';
import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, OnInit} from '@angular/core';
import {Project} from 'src/app/project-builder/shared/model/project';
import * as moment from 'moment-timezone';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ProjectWizardDialogComponent} from 'src/app/project-builder/project-wizard-dialog/project-wizard-dialog.component';
import {Observable} from 'rxjs';
import {Program} from 'src/app/programs/shared/model/program';
import {Store, select} from '@ngrx/store';
import {selectAllPrograms} from 'src/app/core/store/programs/programs.selector';
import {
  CloneProjectTemplateAction,
  DeleteProjectTemplateAction,
  GetAllProjectTemplatesAction,
  ManuallySetCurrentProjectAction,
  ScheduleProjectAction,
  SetCurrentProjectAction,
} from 'src/app/core/store/project-builder/project-builder.action';
import {MessageService} from 'src/app/services/message.service';
import {ConfirmDialogComponent} from 'src/app/core/store/project-builder/unsaved-changes-confirm/confirm-dialog.component';

@Component({
  selector: 'project-edit-menu',
  templateUrl: './project-edit-menu.component.html',
  styleUrls: ['./project-edit-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectEditMenuComponent implements OnChanges, OnInit {
  @Input() project: Project;
  @Input() projects: Project[];

  programs$: Observable<Program[]>;

  buttonDisplay: string;
  buttonColor: string;
  colorHex: string;

  constructor(
    private timezoneService: TimezoneService,
    private dialog: MatDialog,
    private store$: Store,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.programs$ = this.store$.pipe(select(selectAllPrograms));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.project && this.project) {
      this.setButtonDisplay();
    }
  }

  setButtonDisplay() {
    const now = moment().tz(this.timezoneService.getTimeZone()).startOf('day');
    const projectStart = moment(this.project.startDateTime).tz(this.timezoneService.getTimeZone());
    if (this.project.status === 'inactive' || !this.project.startDateTime || !this.project.endDateTime) {
      this.buttonDisplay = 'Draft';
      this.colorHex = '#A3A099';
      this.buttonColor = '';
    } else if (this.project.status === 'active' && now.isBefore(projectStart)) {
      this.buttonDisplay = 'Scheduled';
      // this.buttonColor = '#F57B4E';
      this.buttonColor = 'orange';
      this.colorHex = '';
    } else if (
      this.project.status === 'active' &&
      this.timezoneService.areDatesWithinRange(this.project.startDateTime, this.project.endDateTime)
    ) {
      this.buttonDisplay = 'Active';
      // this.buttonColor = '#7BC143';
      this.buttonColor = 'green';
      this.colorHex = '';
    } else if (
      this.project.status === 'active' &&
      !this.timezoneService.areDatesWithinRange(this.project.startDateTime, this.project.endDateTime)
    ) {
      this.buttonDisplay = 'Expired';
      this.buttonColor = 'red';
      this.colorHex = '';
    }
  }

  onEdit() {
    const dialog = this.dialog.open(ProjectWizardDialogComponent, {
      ...PROJECT_WIZARD_DIALOG_CONFIG,
      data: {project: this.project, programs$: this.programs$},
    });
    dialog.afterClosed().subscribe(projectPartial => {
      if (!projectPartial) {
        return;
      }
      this.store$.dispatch(
        new ScheduleProjectAction({
          id: this.project._id,
          projectPartial,
          task: null,
          taskDeleted: false,
          onSuccess: () => this.onUpdateProjectSuccess(this.project._id),
          onFailure: err => this.onUpdateProjectFailure(err),
        })
      );
    });
  }

  onUpdateProjectSuccess(id: string) {
    this.store$.dispatch(new SetCurrentProjectAction({id}));
  }

  onUpdateProjectFailure(err: Error) {
    this.messageService.add(err?.message);
  }

  onDuplicate() {
    this.store$.dispatch(
      new CloneProjectTemplateAction({
        id: this.project._id,
        onSuccess: template => {
          this.store$.dispatch(new GetAllProjectTemplatesAction());
          this.store$.dispatch(new SetCurrentProjectAction({id: template._id}));
        },
      })
    );
  }

  onDelete() {
    this.dialog
      .open(ConfirmDialogComponent, {
        ...new MatDialogConfig(),
        data: {
          body: `Are you sure you want to delete ${this.project.label}?`,
        },
      })
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.deleteProject();
        }
      });
  }

  deleteProject() {
    this.store$.dispatch(
      new DeleteProjectTemplateAction({
        id: this.project._id,
        onSuccess: () => this.onDeleteSuccess(),
      })
    );
  }

  onDeleteSuccess() {
    localStorage.removeItem('ophProjectId');
    this.store$.dispatch(
      new GetAllProjectTemplatesAction({onSuccess: projects => this.onGetProjectsSuccess(projects)})
    );
  }

  onGetProjectsSuccess(projects: Project[]) {
    if (projects?.length) {
      const id = projects[0]?._id;

      this.store$.dispatch(new SetCurrentProjectAction({id}));
      localStorage.setItem('ophProjectId', id);
    } else {
      this.store$.dispatch(new ManuallySetCurrentProjectAction({project: null}));
    }
  }
}
