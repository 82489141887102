import {ProjectBuilderAction, ProjectBuilderActionType} from './project-builder.action';
import {ProjectBuilderState, initialProjectBuilderState} from './project-builder.state';

export function projectBuilderReducer(
  state: ProjectBuilderState = initialProjectBuilderState,
  action: ProjectBuilderAction
): ProjectBuilderState {
  switch (action.type) {
    case ProjectBuilderActionType.ADD_PROJECT_TEMPLATE:
      return {...state, projectTemplates: [...state.projectTemplates, action.payload]};
    case ProjectBuilderActionType.CLEAR_PROJECT_BUILDER:
      return {
        ...state,
        currentProject: null,
        hasUnsavedChanges: false,
        projectTemplates: [],
        // taskTemplates: dummyTasksTemplates,
        tabIndex: 1,
        type: 'horizontal',
      };
    case ProjectBuilderActionType.CREATE_PROJECT_SUCCESS: {
      const newProject = {...action.payload.project};

      return {
        ...state,
        projectTemplates: [newProject, ...state.projectTemplates],
        currentProject: newProject,
        hasUnsavedChanges: true,
      };
    }
    case ProjectBuilderActionType.CREATE_TASK_SUCCESS:
      return {...state, taskTemplates: [...state.taskTemplates, action.payload.task]};
    case ProjectBuilderActionType.GET_ALL_PROJECTS_SUCCESS:
      return {
        ...state,
        projectTemplates: [...action.payload].map(item => ({...item, tasks: item.tasks || []})),
      };
    case ProjectBuilderActionType.GET_ALL_TASKS_SUCCESS:
      return {...state, taskTemplates: [...action.payload]};
    case ProjectBuilderActionType.SAVE_CURRENT_PROJECT:
      return {...state, saveInProgress: true};
    case ProjectBuilderActionType.SAVE_CURRENT_PROJECT_SUCCESS:
      return {...state, currentProject: action.payload.project, hasUnsavedChanges: false, saveInProgress: false};
    case ProjectBuilderActionType.SET_SAVE_IN_PROGRESS:
      return {...state, saveInProgress: action.payload.saveInProgress};
    case ProjectBuilderActionType.SET_CURRENT_PROJECT_SUCCESS:
      if (!action.payload.project) {
        return {...state, currentProject: null};
      }
      return {
        ...state,
        currentProject: {
          ...action.payload.project,
          tasks: action.payload.project.tasks,
        },
        hasUnsavedChanges: false,
      };
    case ProjectBuilderActionType.MANUALLY_SET_CURRENT_PROJECT:
      return {...state, currentProject: action.payload.project};
    case ProjectBuilderActionType.SET_TAB_INDEX:
      return {...state, tabIndex: action.payload.tabIndex};
    case ProjectBuilderActionType.SET_TYPE:
      return {...state, type: action.payload.type};
    case ProjectBuilderActionType.SET_UNSAVED_CHANGES:
      return {...state, hasUnsavedChanges: action.payload};
    case ProjectBuilderActionType.GET_PROJECT_VIEWER_PROJECT_SUCCESS:
      return {...state, projectViewerProject: action.payload.project};
    case ProjectBuilderActionType.UPDATE_PROJECT_VIEWER_TASK_ID:
      return {...state, projectViewerTaskId: action.payload.id};
    default:
      return state;
  }
}
