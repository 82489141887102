import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {convertObjectDtoToModel} from '../../../objects/shared/converters/convert-object-dto-to-model';
import {convertObjectInstanceDtoToModel} from '../../../objects/shared/converters/convert-object-instance-dto-to-model';
import {
  convertObjectAttributeModelToDto,
  convertObjectModelToDto,
} from '../../../objects/shared/converters/convert-object-model-to-dto';
import {ObjectInstanceDto} from '../../../objects/shared/dto/object-instance.dto';
import {ObjectDto} from '../../../objects/shared/dto/object.dto';
import {ObjectsApiService} from '../../api/objects-api.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  CreateObjectAction,
  CreateObjectInstanceAction,
  CreateObjectInstanceSuccessAction,
  CreateObjectSuccessAction,
  DeleteObjectAction,
  DeleteObjectAttributeAction,
  DeleteObjectInstanceAction,
  DeleteObjectSuccessAction,
  DuplicateObjectAction,
  DuplicateObjectSuccessAction,
  GetAllObjectInstancesAction,
  GetAllObjectInstancesSuccessAction,
  GetCombinedObjectsAction,
  GetCombinedObjectsSuccessAction,
  GetObjectInstancesAction,
  GetObjectInstancesFromInstanceIdAction,
  GetObjectInstancesSuccessAction,
  GetObjectsAction,
  GetObjectsSuccessAction,
  GetSingleObjectAction,
  GetSingleObjectSuccessAction,
  ObjectInstanceValueChangeAction,
  ObjectInstanceValueChangeSuccessAction,
  ObjectsActionType,
  UpdateObjectAttributeAction,
  UpdateObjectInstanceAction,
  UpdateObjectInstanceSuccessAction,
  UpdateObjectNameAction,
  UpdateObjectNameSuccessAction,
} from './objects.action';

@Injectable()
export class ObjectsEffects {
  public get$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetObjectsAction>(ObjectsActionType.GET),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;

        return this.objectsApiService.get(params).pipe(
          map((dtos: ObjectDto[]) => dtos.map(dto => convertObjectDtoToModel(dto))),
          mergeMap(objects => [new GetObjectsSuccessAction({objects}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getSingle$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSingleObjectAction>(ObjectsActionType.GET_SINGLE),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;

        return this.objectsApiService.getSingle(id).pipe(
          map((dtos: ObjectDto[]) => dtos.map(dto => convertObjectDtoToModel(dto))),
          mergeMap(objects => [
            new GetSingleObjectSuccessAction({object: objects[0]}),
            ...createCallbackActions(onSuccess, objects[0]),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getCombined$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetCombinedObjectsAction>(ObjectsActionType.GET_COMBINED),
      mergeMap(action => {
        const {onSuccess, onFailure} = action.payload;

        return this.objectsApiService.getCombined().pipe(
          mergeMap(objects => [new GetCombinedObjectsSuccessAction({objects}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getInstances$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetObjectInstancesAction>(ObjectsActionType.GET_INSTANCES),
      mergeMap(action => {
        const {modelId, onSuccess, onFailure} = action.payload;

        return this.objectsApiService.getInstances(modelId).pipe(
          map((dtos: ObjectInstanceDto[]) => dtos.map(dto => convertObjectInstanceDtoToModel(dto))),
          mergeMap(objectInstances => [
            new GetObjectInstancesSuccessAction({objectInstances}),
            ...createCallbackActions(onSuccess, objectInstances),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getAllInstances$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAllObjectInstancesAction>(ObjectsActionType.GET_ALL_INSTANCES),
      mergeMap(action => {
        const {onSuccess, onFailure} = action.payload;

        return this.objectsApiService.getAllInstances().pipe(
          map((dtos: ObjectInstanceDto[]) => dtos.map(dto => convertObjectInstanceDtoToModel(dto))),
          mergeMap(objectInstances => [
            new GetAllObjectInstancesSuccessAction({objectInstances}),
            ...createCallbackActions(onSuccess, objectInstances),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getInstancesFromInstanceId$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetObjectInstancesFromInstanceIdAction>(ObjectsActionType.GET_INSTANCES_FROM_INSTANCE_ID),
      mergeMap(action => {
        const {instanceId, onSuccess, onFailure} = action.payload;

        return this.objectsApiService.getInstancesFromInstanceId(instanceId).pipe(
          map((dtos: ObjectInstanceDto[]) => dtos.map(dto => convertObjectInstanceDtoToModel(dto))),
          mergeMap(objectInstances => [
            new GetObjectInstancesSuccessAction({objectInstances}),
            ...createCallbackActions(onSuccess, objectInstances),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public createInstance$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateObjectInstanceAction>(ObjectsActionType.CREATE_INSTANCE),
      mergeMap(action => {
        const {object, onSuccess, onFailure} = action.payload;
        const objectDto = convertObjectModelToDto(object);

        return this.objectsApiService.createInstance(objectDto).pipe(
          map((dto: ObjectInstanceDto) => convertObjectInstanceDtoToModel(dto)),
          mergeMap(o => [
            new CreateObjectInstanceSuccessAction({objectInstance: o}),
            ...createCallbackActions(onSuccess, o),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public create$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateObjectAction>(ObjectsActionType.CREATE),
      mergeMap(action => {
        const {object, onSuccess, onFailure} = action.payload;
        const objectDto = convertObjectModelToDto(object);

        return this.objectsApiService.create(objectDto).pipe(
          map((dto: ObjectDto) => convertObjectDtoToModel(dto)),
          mergeMap(o => [new CreateObjectSuccessAction({object: o}), ...createCallbackActions(onSuccess, o)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public duplicate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DuplicateObjectAction>(ObjectsActionType.DUPLICATE),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;

        return this.objectsApiService.duplicate(id).pipe(
          map((dto: ObjectDto) => convertObjectDtoToModel(dto)),
          mergeMap(o => [new DuplicateObjectSuccessAction({object: o}), ...createCallbackActions(onSuccess, o)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public updateName$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateObjectNameAction>(ObjectsActionType.UPDATE_NAME),
      mergeMap(action => {
        const {id, name, onSuccess, onFailure} = action.payload;

        return this.objectsApiService.updateName(id, name).pipe(
          map((dto: ObjectDto) => convertObjectDtoToModel(dto)),
          mergeMap(l => [
            new GetSingleObjectAction({id}),
            new UpdateObjectNameSuccessAction({object: l}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteObjectAction>(ObjectsActionType.DELETE),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;

        return this.objectsApiService.delete(id).pipe(
          mergeMap(programId => [new DeleteObjectSuccessAction(), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public updateAttribute$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateObjectAttributeAction>(ObjectsActionType.UPDATE_ATTRIBUTE),
      mergeMap(action => {
        const {objectId, attribute, newAttribute, onSuccess, onFailure} = action.payload;
        const attributeDto = convertObjectAttributeModelToDto(attribute);

        return this.objectsApiService.updateAttribute(objectId, attributeDto, newAttribute).pipe(
          map((dto: ObjectDto) => convertObjectDtoToModel(dto)),
          mergeMap(o => [new UpdateObjectNameSuccessAction({object: o}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public deleteAttribute$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteObjectAttributeAction>(ObjectsActionType.DELETE_ATTRIBUTE),
      mergeMap(action => {
        const {objectId, attribute, onSuccess, onFailure} = action.payload;
        const attributeDto = convertObjectAttributeModelToDto(attribute);

        return this.objectsApiService.deleteAttribute(objectId, attributeDto).pipe(
          map((dto: ObjectDto) => convertObjectDtoToModel(dto)),
          mergeMap(o => [new UpdateObjectNameSuccessAction({object: o}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public instanceValueChange$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<ObjectInstanceValueChangeAction>(ObjectsActionType.VALUE_CHANGE),
      mergeMap(action => {
        const {objectInstanceId, instanceAttributeId, value, onSuccess, onFailure} = action.payload;

        return this.objectsApiService.instanceValueChange(objectInstanceId, instanceAttributeId, value).pipe(
          mergeMap(() => [new ObjectInstanceValueChangeSuccessAction(), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public updateInstance$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateObjectInstanceAction>(ObjectsActionType.UPDATE_INSTANCE),
      mergeMap(action => {
        const {objectInstance, onSuccess, onFailure} = action.payload;

        return this.objectsApiService.updateInstance(objectInstance).pipe(
          mergeMap(() => [new UpdateObjectInstanceSuccessAction(), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public deleteInstance$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteObjectInstanceAction>(ObjectsActionType.DELETE_INSTANCE),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;

        return this.objectsApiService.deleteInstance(id).pipe(
          mergeMap(() => [new DeleteObjectSuccessAction(), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private objectsApiService: ObjectsApiService
  ) {}
}
