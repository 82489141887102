<oph-button
  [matMenuTriggerFor]="projectMenu"
  appearance="solid"
  [color]="buttonColor"
  [colorHex]="colorHex"
  [style]="{width: '142px', justifyContent: 'space-between', color: 'white'}"
>
  {{ buttonDisplay }}
  <img src="assets/img/icons/chevron-down-white.svg" />
</oph-button>
<mat-menu #projectMenu="matMenu" class="project-menu">
  <button class="oph-select-menu-item" (click)="onEdit()">
    <span>Edit</span>
    <mat-icon svgIcon="pencil-outline"></mat-icon>
  </button>
  <button class="oph-select-menu-item" (click)="onDuplicate()">
    <span>Duplicate</span>
    <mat-icon svgIcon="plus"></mat-icon>
  </button>
  <button class="oph-select-menu-item" (click)="onDelete()">
    <span>Delete</span>
    <mat-icon svgIcon="x-circle"></mat-icon>
  </button>
</mat-menu>
