import {CurrentSkedAction, CurrentSkedActionType} from './current-sked.action';
import {CurrentSkedState, initialCurrentSkedState} from './current-sked.state';

export function currentSkedReducer(
  state: CurrentSkedState = initialCurrentSkedState,
  action: CurrentSkedAction
): CurrentSkedState {
  switch (action.type) {
    case CurrentSkedActionType.GET_SUCCESS:
      return {...state, currentSked: action.payload.currentSked};
    case CurrentSkedActionType.GET_ALL_SKEDS_SUCCESS:
      return {...state, skeds: action.payload.skeds};
    case CurrentSkedActionType.GET_TASK_SUCCESS:
      return {...state, task: action.payload.task};
    case CurrentSkedActionType.CHANGE_THEME:
      return {...state, theme: action.payload.theme};
    case CurrentSkedActionType.UPDATE_ALL_SKEDS:
      return {...state, skeds: action.payload.allSkeds};
    case CurrentSkedActionType.UPDATE_ACTIVE_TAB:
      return {...state, activeTab: action.payload.activeTab};
    case CurrentSkedActionType.UPDATE_SELECTED_SKED_TYPE:
      return {...state, selectedSkedType: action.payload.selectedSkedType};
    case CurrentSkedActionType.UPDATE_EXPANDED_SUBTASK_INDEX:
      return {...state, expandedSubtaskIndex: action.payload.expandedSubtaskIndex};
    default:
      return state;
  }
}
