import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SkillModel} from '../core/model/skill';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SkillsApiService {
  private readonly url: string = `${environment.apiUrl}/requirements`;

  constructor(private http: HttpClient) {}

  fetchAll(search: string | null): Observable<SkillModel[]> {
    return this.http
      .get<SkillModel[]>(`${this.url}`)
      .pipe(map(skills => skills.filter(s => !search || s.name.toLowerCase().includes(search))));
  }

  getSingleSkill(id: string): Observable<SkillModel> {
    return this.http.get<SkillModel[]>(`${this.url}/${id}`).pipe(map(res => res[0]));
  }

  saveSkill(skill: SkillModel): Observable<SkillModel> {
    if (skill._id !== 'new') {
      return this.http.put<SkillModel>(`${this.url}/${skill._id}`, skill);
    }

    delete skill._id;
    return this.http.post<SkillModel>(`${this.url}`, skill);
  }

  removeSkill(id: string): Observable<void> {
    return this.http.delete<void>(`${this.url}/${id}`);
  }
}
